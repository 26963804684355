import type { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import { getBase64 } from "app/utils";
import { useIntl } from "react-intl";
import { createPathCloudFrame } from "../core/_requests";
import { useDropzone } from "react-dropzone";

const UploadImg = ({ updateFormData, error, url = "" }: any) => {
  const [imageUrl, setImageUrl] = useState<string>(url);

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const url = await getBase64(file as RcFile);
    setImageUrl(url);

    const res = await createPathCloudFrame({
      file_names: [`event/${file.lastModified}.png`],
    });

    if (res && res.data && res.data.code === 200) {
      const { url, file_name } = res.data.data.list[0];
      updateFormData({ url, file, image: file_name });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className="overflow-hidden"
        style={{
          width: "100%",
          height: "200px",
          cursor: "pointer",
          border: "1px dashed #000",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            className="object-fit-cover w-full h-100"
          />
        ) : (
          <p>Upload</p>
        )}
      </div>
    </div>
  );
};

export default UploadImg;
