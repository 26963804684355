import { useIntl } from "react-intl";

export default function LanguageTabItem({
  title,
  titlePlaceholder,
  data,
  onChangeData,
  hasError,
  content,
  contentPlaceholder,
  lang = "ko",
}: any) {
  const intl = useIntl();

  if (data && typeof data?.title === "string") {
    data.title = JSON.parse({ ...data }.title);
    data.body = JSON.parse({ ...data }.body);
  }

  return (
    <>
      <div className='fv-row mb-3'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='fs-5 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required'>
            {intl.formatMessage({ id: title })}
          </span>
        </label>
        <textarea
          className='form-control form-control-lg form-control-solid'
          name='appname'
          placeholder={intl.formatMessage({
            id: titlePlaceholder,
          })}
          required
          onChange={(e) => onChangeData(e.target.value, "title", lang)}
          autoFocus
          style={{
            resize: "none",
          }}
        />
        {hasError?.name && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              {hasError?.name}
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className='fs-5 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required'>
            {intl.formatMessage({ id: content })}
          </span>
        </label>
        <textarea
          className='form-control form-control-lg form-control-solid'
          required
          name='appname'
          placeholder={intl.formatMessage({ id: contentPlaceholder })}
          onChange={(e) => onChangeData(e.target.value, "body", lang)}
          style={{ height: 150, resize: "none" }}
        />
        {hasError?.body && (
          <div className='fv-plugins-message-container'>
            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
              {hasError?.body}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
