/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
// import { create_admin, edit_admin } from "../_requests";
import { Box, CircularProgress, Typography } from "@mui/material";
import { notification } from "antd";
import { useIntl } from "react-intl";

import { createEvent, putUpload } from "../core/_requests";
import { Step1 } from "./Step1";

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({
  show,
  handleClose,
  info,
  triggerListNotify,
  triggerListEvent,
}: any) => {
  const intl = useIntl();

  const [data, setData] = useState<any>();
  const [dataOptions, setDataOptions] = useState<any>();
  const [error, setError] = useState(null);
  const [isLoadingAddNewEvent, setIsLoadingAddNewEvent] = useState(false);

  const updateData = (fieldsToUpdate: any) => {
    setData(fieldsToUpdate);
  };

  const handleOnCloseModal = () => {
    handleClose();
  };

  const updateError = (value: any) => {
    setError(value);
  };
  const handleSubmitAddEvent = async () => {
    setIsLoadingAddNewEvent(true);
    const upload = await putUpload({ url: data.url, file: data.file });
    if (!upload) {
      notification.error({
        message: (
          <Box>
            <Typography
              fontWeight={700}
              fontSize={16}
              color={"red"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              {intl.formatMessage({ id: "ADD-NEW-EVENT-ERROR" })}
            </Typography>
          </Box>
        ),
      });
    }
    const response = await createEvent(data);
    if (response.data?.code === 200) {
      setError(null);
      notification.success({
        message: (
          <Box>
            <Typography
              fontWeight={700}
              fontSize={16}
              color={"black"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              {intl.formatMessage({ id: "ADD-NEW-EVENT-SUCCESS" })}
            </Typography>
          </Box>
        ),
      });
      handleOnCloseModal();
      triggerListEvent();
    } else {
      if (response.data?.errors) {
        setError(response.data?.errors);
      }
    }
    setIsLoadingAddNewEvent(false);
  };

  const isValidated = () => {
    const isTitle =
      data?.title.some((t: any) => t?.text === "") || data?.title?.length < 2;

    return (
      isTitle ||
      !data?.title ||
      !data?.file ||
      !data.start_date ||
      !data.end_date ||
      isLoadingAddNewEvent
    );
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px "
      show={show}
      onHide={handleOnCloseModal}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{intl.formatMessage({ id: "ADD-NEW-EVENT" })}</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div className="px-lg-5 w-full">
            {/*begin::Form */}
            <form noValidate id="kt_modal_create_app_form">
              <Step1
                data={data}
                error={error}
                updateData={updateData}
                info={info}
                dataOptions={dataOptions}
                isLoading={false}
                triggerListTypeNotify={() => {}}
                updateError={updateError}
              />

              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2"></div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-primary min-w-150px"
                    data-kt-stepper-action="next"
                    disabled={isValidated()}
                    onClick={handleSubmitAddEvent}
                  >
                    {isLoadingAddNewEvent ? (
                      <CircularProgress size={16} />
                    ) : (
                      intl.formatMessage({ id: "ADD-EVENT" })
                    )}
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
