import { TreeSelect } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useQueryRequest } from "./coreListData/core/QueryRequestProvider";

const TreeSelectC = ({
  allItems,
  defaultIDs,
  changeAction,
  type = "",
  state,
  updateState,
}: any) => {
  const intl = useIntl();

  const [selectedValues, setSelectedValues] = useState([]);

  let allIds: any = allItems?.map((i: any) => i.value);

  let allLabel: any = allItems?.map((i: any) => i.label);

  useEffect(() => {
    updateState({ ...state, [type]: selectedValues.toString(), page: 1 });
  }, [selectedValues]);

  return (
    <TreeSelect
      allowClear={true}
      placeholder={intl.formatMessage({ id: "SEARCH-AND-SELECT" })}
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_CHILD}
      style={{ height: "35px" }}
      dropdownStyle={{ maxHeight: "300px" }}
      className="min-w-100 min-w-sm-250px"
      onChange={(ids) => {
        setSelectedValues(ids);
      }}
      value={
        type == "type"
          ? selectedValues
          : (state[type] && state[type].split(",")) || []
      }
      maxTagCount={2}
      maxTagPlaceholder={(omittedValues) => `+ ${omittedValues?.length}`}
      filterTreeNode={(search, item) => {
        if (typeof item.label == "string")
          return item?.label.toLowerCase().includes(search.toLowerCase());
        return false;
      }}
      treeData={[
        {
          title: (
            <React.Fragment>
              {selectedValues?.length === allItems?.length && (
                <span
                  style={{
                    display: "inline-block",
                    color: "#ccc",
                    cursor: "not-allowed",
                  }}
                >
                  {intl.formatMessage({ id: "SELECT-ALL" })}
                </span>
              )}
              {selectedValues?.length < allItems?.length && (
                <span
                  onClick={() => setSelectedValues(allIds)}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer",
                  }}
                >
                  {intl.formatMessage({ id: "SELECT-ALL" })}
                </span>
              )}
              &nbsp;&nbsp;&nbsp;
              {selectedValues?.length === 0 && (
                <span
                  style={{
                    display: "inline-block",
                    color: "#ccc",
                    cursor: "not-allowed",
                  }}
                >
                  {intl.formatMessage({ id: "UNSELECT-ALL" })}
                </span>
              )}
              {selectedValues?.length > 0 && (
                <span
                  onClick={() => setSelectedValues([])}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer",
                  }}
                >
                  {intl.formatMessage({ id: "UNSELECT-ALL" })}
                </span>
              )}
            </React.Fragment>
          ),
          disableCheckbox: true,
          disabled: true,
        },
        ...allItems,
      ]}
    />
  );
};

export default TreeSelectC;
