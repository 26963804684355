// @ts-nocheck
import { Column } from "react-table";
import { User } from "../../core/_models";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="ID" className="min-w-50px" />
    ),
    id: "id",
    accessor: "id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Car"
        className="min-w-125px"
      />
    ),

    id: "car.reg_name",
    accessor: "car.reg_name",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Departure"
        className="min-w-125px"
      />
    ),

    id: "start_address",
    accessor: "start_address",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Destination"
        className="min-w-125px"
      />
    ),

    id: "destination_address",
    accessor: "destination_address",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Result"
        className="min-w-125px"
      />
    ),

    id: "result_status",
    accessor: "result_status",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Total Carbon"
        className="min-w-125px"
      />
    ),

    id: "total_carbon_out",
    accessor: "total_carbon_out",
  },
];

export { usersColumns };
