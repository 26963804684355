// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { restore_user } from "../core/_requests";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";

const usersColumns: any = (hasPermissionUpdate: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <PlatformCell className="min-w-150px" user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const index = row.nickname.indexOf("-del");
      let rowView = { ...row };
      if (index > 0) {
        rowView = { ...row, nickname: row.nickname.substr(0, index) };
      }
      return <UserInfoCell user={rowView} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isForceDesc={true}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-150px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.updated_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();

      return (
        <div className="d-flex" style={{ gap: 5 }}>
          {hasPermissionUpdate && <RevertComponent row={row} />}
          {/* <Tooltip placement="top" title="User detail">
            <Link
              to={`${location.pathname}/detail/${props.row.original.id}`}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
            >
              <KTIcon iconName="arrow-right" className="fs-2" />
            </Link>
          </Tooltip> */}
        </div>
      );
    },
  },
];

const PlatformCell = ({ user, className = "" }) => {
  let imageSrc;
  switch (user?.platform) {
    case "amazon":
      imageSrc = toAbsoluteUrl("/media/social_media/amazon.svg");
      break;

    case "apple":
      imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
      break;

    case "facebook":
      imageSrc = toAbsoluteUrl("/media/social_media/facebook.svg");
      break;

    case "google":
      imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
      break;

    case "kakao":
      imageSrc = toAbsoluteUrl("/media/social_media/kakao.svg");
      break;

    case "naver":
      imageSrc = toAbsoluteUrl("/media/social_media/naver.svg");
      break;

    default:
      imageSrc = toAbsoluteUrl("/media/logos/picaverse.svg");
  }

  return (
    <div className={`${className} d-flex align-items-center`}>
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <div className={clsx("symbol-label fs-3")}>
          <img src={imageSrc} className="w-50" />
        </div>
      </div>
    </div>
  );
};

function RevertComponent({ row }: any) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingRevertArticle, setIsLoadingRevertArticle] = useState(false);
  const handleRevertArticle = async () => {
    setIsLoadingRevertArticle(true);
    const res = await restore_user(row.id);
    setIsLoadingRevertArticle(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "REVERT-SUCCESS" }),
      });
      refetch();
    }
  };
  return (
    <>
      <Popconfirm
        title={intl.formatMessage({ id: "RESTORE" })}
        description={intl.formatMessage({ id: "USER.RESTORE.CONFIRM" })}
        onConfirm={handleRevertArticle}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingRevertArticle ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon
              iconType="solid"
              iconName="arrow-circle-left"
              className="fs-2"
            />
          )}
        </button>
      </Popconfirm>
    </>
  );
}

export { usersColumns };
