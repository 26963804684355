export const COUNTRY = {
  AF: {
    name: "Afghanistan",
    alpha2Code: "AF",
    dial_code: "+93",
    country_code: "fa_AF",
  },
  AL: {
    name: "Albania",
    alpha2Code: "AL",
    dial_code: "+355",
    country_code: "sq_AL",
  },
  DZ: {
    name: "Algeria",
    alpha2Code: "DZ",
    dial_code: "+213",
    country_code: "ar_DZ",
  },
  AS: {
    name: "American Samoa",
    alpha2Code: "AS",
    dial_code: "+1684",
    country_code: "en_AS",
  },
  AD: {
    name: "Andorra",
    alpha2Code: "AD",
    dial_code: "+376",
    country_code: "ca_AD",
  },
  AO: {
    name: "Angola",
    alpha2Code: "AO",
    dial_code: "+244",
    country_code: "pt_AO",
  },
  AI: {
    name: "Anguilla",
    alpha2Code: "AI",
    dial_code: "+1264",
    country_code: "en_AI",
  },
  AQ: {
    name: "Antarctica",
    alpha2Code: "AQ",
    dial_code: "+672",
    country_code: "en_AQ",
  },
  AG: {
    name: "Antigua and Barbuda",
    alpha2Code: "AG",
    dial_code: "+1268",
    country_code: "en_AG",
  },
  AR: {
    name: "Argentina",
    alpha2Code: "AR",
    dial_code: "+54",
    country_code: "es_AR",
  },
  AM: {
    name: "Armenia",
    alpha2Code: "AM",
    dial_code: "+374",
    country_code: "hy_AM",
  },
  AW: {
    name: "Aruba",
    alpha2Code: "AW",
    dial_code: "+297",
    country_code: "nl_AW",
  },
  AU: {
    name: "Australia",
    alpha2Code: "AU",
    dial_code: "+61",
    country_code: "en_AU",
  },
  AT: {
    name: "Austria",
    alpha2Code: "AT",
    dial_code: "+43",
    country_code: "de_AT",
  },
  AZ: {
    name: "Azerbaijan",
    alpha2Code: "AZ",
    dial_code: "+994",
    country_code: "az_AZ",
  },
  BS: {
    name: "Bahamas",
    alpha2Code: "BS",
    dial_code: "+1242",
    country_code: "en_BS",
  },
  BH: {
    name: "Bahrain",
    alpha2Code: "BH",
    dial_code: "+973",
    country_code: "ar_BH",
  },
  BD: {
    name: "Bangladesh",
    alpha2Code: "BD",
    dial_code: "+880",
    country_code: "bn_BD",
  },
  BB: {
    name: "Barbados",
    alpha2Code: "BB",
    dial_code: "+1246",
    country_code: "en_BB",
  },
  BY: {
    name: "Belarus",
    alpha2Code: "BY",
    dial_code: "+375",
    country_code: "be_BY",
  },
  BE: {
    name: "Belgium",
    alpha2Code: "BE",
    dial_code: "+32",
    country_code: "nl_BE",
  },
  BZ: {
    name: "Belize",
    alpha2Code: "BZ",
    dial_code: "+501",
    country_code: "en_BZ",
  },
  BJ: {
    name: "Benin",
    alpha2Code: "BJ",
    dial_code: "+229",
    country_code: "fr_BJ",
  },
  BM: {
    name: "Bermuda",
    alpha2Code: "BM",
    dial_code: "+1441",
    country_code: "en_BM",
  },
  BT: {
    name: "Bhutan",
    alpha2Code: "BT",
    dial_code: "+975",
    country_code: "dz_BT",
  },
  BO: {
    name: "Bolivia (Plurinational State of)",
    alpha2Code: "BO",
    dial_code: "+591",
    country_code: "es_BO",
  },
  BQ: {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2Code: "BQ",
    dial_code: "+5997",
    country_code: "nl_BQ",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    alpha2Code: "BA",
    dial_code: "+387",
    country_code: "bs_BA",
  },
  BW: {
    name: "Botswana",
    alpha2Code: "BW",
    dial_code: "+267",
    country_code: "en_BW",
  },
  BV: {
    name: "Bouvet Island",
    alpha2Code: "BV",
    dial_code: "+47",
    country_code: "no_NB",
  },
  BR: {
    name: "Brazil",
    alpha2Code: "BR",
    dial_code: "+55",
    country_code: "pt_BR",
  },
  IO: {
    name: "British Indian Ocean Territory",
    alpha2Code: "IO",
    dial_code: "+246",
    country_code: "en_IO",
  },
  BN: {
    name: "Brunei Darussalam",
    alpha2Code: "BN",
    dial_code: "+673",
    country_code: "ms_BN",
  },
  BG: {
    name: "Bulgaria",
    alpha2Code: "BG",
    dial_code: "+359",
    country_code: "bg_BG",
  },
  BF: {
    name: "Burkina Faso",
    alpha2Code: "BF",
    dial_code: "+226",
    country_code: "fr_BF",
  },
  BI: {
    name: "Burundi",
    alpha2Code: "BI",
    dial_code: "+257",
    country_code: "fr_BI",
  },
  CV: {
    name: "Cabo Verde",
    alpha2Code: "CV",
    dial_code: "+238",
    country_code: "pt_CV",
  },
  KH: {
    name: "Cambodia",
    alpha2Code: "KH",
    dial_code: "+855",
    country_code: "km_KH",
  },
  CM: {
    name: "Cameroon",
    alpha2Code: "CM",
    dial_code: "+237",
    country_code: "en_CM",
  },
  CA: {
    name: "Canada",
    alpha2Code: "CA",
    dial_code: "+1",
    country_code: "en_CA",
  },
  KY: {
    name: "Cayman Islands",
    alpha2Code: "KY",
    dial_code: "+1345",
    country_code: "en_KY",
  },
  CF: {
    name: "Central African Republic",
    alpha2Code: "CF",
    dial_code: "+236",
    country_code: "fr_CF",
  },
  TD: {
    name: "Chad",
    alpha2Code: "TD",
    dial_code: "+235",
    country_code: "fr_TD",
  },
  CL: {
    name: "Chile",
    alpha2Code: "CL",
    dial_code: "+56",
    country_code: "es_CL",
  },
  CN: {
    name: "China",
    alpha2Code: "CN",
    dial_code: "+86",
    country_code: "zh_CN",
  },
  CX: {
    name: "Christmas Island",
    alpha2Code: "CX",
    dial_code: "+61",
    country_code: "en_CX",
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    alpha2Code: "CC",
    dial_code: "+61",
    country_code: "en_CC",
  },
  CO: {
    name: "Colombia",
    alpha2Code: "CO",
    dial_code: "+57",
    country_code: "es_CO",
  },
  KM: {
    name: "Comoros",
    alpha2Code: "KM",
    dial_code: "+269",
    country_code: "ar_KM",
  },
  CG: {
    name: "Congo",
    alpha2Code: "CG",
    dial_code: "+242",
    country_code: "fr_CG",
  },
  CD: {
    name: "Congo (Democratic Republic of the)",
    alpha2Code: "CD",
    dial_code: "+243",
    country_code: "fr_CD",
  },
  CK: {
    name: "Cook Islands",
    alpha2Code: "CK",
    dial_code: "+682",
    country_code: "en_CK",
  },
  CR: {
    name: "Costa Rica",
    alpha2Code: "CR",
    dial_code: "+506",
    country_code: "es_CR",
  },
  HR: {
    name: "Croatia",
    alpha2Code: "HR",
    dial_code: "+385",
    country_code: "hr_HR",
  },
  CU: {
    name: "Cuba",
    alpha2Code: "CU",
    dial_code: "+53",
    country_code: "es_CU",
  },
  CW: {
    name: "Curaçao",
    alpha2Code: "CW",
    dial_code: "+599",
    country_code: "nl_CW",
  },
  CY: {
    name: "Cyprus",
    alpha2Code: "CY",
    dial_code: "+357",
    country_code: "el_CY",
  },
  CZ: {
    name: "Czech Republic",
    alpha2Code: "CZ",
    dial_code: "+420",
    country_code: "cs_CZ",
  },
  DK: {
    name: "Denmark",
    alpha2Code: "DK",
    dial_code: "+45",
    country_code: "da_DK",
  },
  DJ: {
    name: "Djibouti",
    alpha2Code: "DJ",
    dial_code: "+253",
    country_code: "fr_DJ",
  },
  DM: {
    name: "Dominica",
    alpha2Code: "DM",
    dial_code: "+1767",
    country_code: "en_DM",
  },
  DO: {
    name: "Dominican Republic",
    alpha2Code: "DO",
    dial_code: "+1809",
    country_code: "es_DO",
  },
  EC: {
    name: "Ecuador",
    alpha2Code: "EC",
    dial_code: "+593",
    country_code: "es_EC",
  },
  EG: {
    name: "Egypt",
    alpha2Code: "EG",
    dial_code: "+20",
    country_code: "ar_EG",
  },
  SV: {
    name: "El Salvador",
    alpha2Code: "SV",
    dial_code: "+503",
    country_code: "es_SV",
  },
  GQ: {
    name: "Equatorial Guinea",
    alpha2Code: "GQ",
    dial_code: "+240",
    country_code: "es_GQ",
  },
  ER: {
    name: "Eritrea",
    alpha2Code: "ER",
    dial_code: "+291",
    country_code: "ti_ER",
  },
  EE: {
    name: "Estonia",
    alpha2Code: "EE",
    dial_code: "+372",
    country_code: "et_EE",
  },
  ET: {
    name: "Ethiopia",
    alpha2Code: "ET",
    dial_code: "+251",
    country_code: "am_ET",
  },
  FK: {
    name: "Falkland Islands (Malvinas)",
    alpha2Code: "FK",
    dial_code: "+500",
    country_code: "en_FK",
  },
  FO: {
    name: "Faroe Islands",
    alpha2Code: "FO",
    dial_code: "+298",
    country_code: "fo_FO",
  },
  FJ: {
    name: "Fiji",
    alpha2Code: "FJ",
    dial_code: "+679",
    country_code: "en_FJ",
  },
  FI: {
    name: "Finland",
    alpha2Code: "FI",
    dial_code: "+358",
    country_code: "fi_FI",
  },
  FR: {
    name: "France",
    alpha2Code: "FR",
    dial_code: "+33",
    country_code: "fr_FR",
  },
  GF: {
    name: "French Guiana",
    alpha2Code: "GF",
    dial_code: "+594",
    country_code: "fr_GF",
  },
  PF: {
    name: "French Polynesia",
    alpha2Code: "PF",
    dial_code: "+689",
    country_code: "fr_PF",
  },
  TF: {
    name: "French Southern Territories",
    alpha2Code: "TF",
    dial_code: "+262",
    country_code: "fr_TF",
  },
  GA: {
    name: "Gabon",
    alpha2Code: "GA",
    dial_code: "+241",
    country_code: "fr_GA",
  },
  GM: {
    name: "Gambia",
    alpha2Code: "GM",
    dial_code: "+220",
    country_code: "en_GM",
  },
  GE: {
    name: "Georgia",
    alpha2Code: "GE",
    dial_code: "+995",
    country_code: "ka_GE",
  },
  DE: {
    name: "Germany",
    alpha2Code: "DE",
    dial_code: "+49",
    country_code: "de_DE",
  },
  GH: {
    name: "Ghana",
    alpha2Code: "GH",
    dial_code: "+233",
    country_code: "en_GH",
  },
  GI: {
    name: "Gibraltar",
    alpha2Code: "GI",
    dial_code: "+350",
    country_code: "en_GI",
  },
  GR: {
    name: "Greece",
    alpha2Code: "GR",
    dial_code: "+30",
    country_code: "el_GR",
  },
  GL: {
    name: "Greenland",
    alpha2Code: "GL",
    dial_code: "+299",
    country_code: "kl_GL",
  },
  GD: {
    name: "Grenada",
    alpha2Code: "GD",
    dial_code: "+1473",
    country_code: "en_GD",
  },
  GP: {
    name: "Guadeloupe",
    alpha2Code: "GP",
    dial_code: "+590",
    country_code: "fr_GP",
  },
  GU: {
    name: "Guam",
    alpha2Code: "GU",
    dial_code: "+1671",
    country_code: "en_GU",
  },
  GT: {
    name: "Guatemala",
    alpha2Code: "GT",
    dial_code: "+502",
    country_code: "es_GT",
  },
  GG: {
    name: "Guernsey",
    alpha2Code: "GG",
    dial_code: "+44",
    country_code: "en_GG",
  },
  GN: {
    name: "Guinea",
    alpha2Code: "GN",
    dial_code: "+224",
    country_code: "fr_GN",
  },
  GW: {
    name: "Guinea-Bissau",
    alpha2Code: "GW",
    dial_code: "+245",
    country_code: "pt_GW",
  },
  GY: {
    name: "Guyana",
    alpha2Code: "GY",
    dial_code: "+592",
    country_code: "en_GY",
  },
  HT: {
    name: "Haiti",
    alpha2Code: "HT",
    dial_code: "+509",
    country_code: "fr_HT",
  },
  HM: {
    name: "Heard Island and McDonald Islands",
    alpha2Code: "HM",
    dial_code: "+672",
    country_code: "en_HM",
  },
  VA: {
    name: "Holy See",
    alpha2Code: "VA",
    dial_code: "+379",
    country_code: "la_VA",
  },
  HN: {
    name: "Honduras",
    alpha2Code: "HN",
    dial_code: "+504",
    country_code: "es_HN",
  },
  HK: {
    name: "Hong Kong",
    alpha2Code: "HK",
    dial_code: "+852",
    country_code: "zh_HK",
  },
  HU: {
    name: "Hungary",
    alpha2Code: "HU",
    dial_code: "+36",
    country_code: "hu_HU",
  },
  IS: {
    name: "Iceland",
    alpha2Code: "IS",
    dial_code: "+354",
    country_code: "is_IS",
  },
  IN: {
    name: "India",
    alpha2Code: "IN",
    dial_code: "+91",
    country_code: "hi_IN",
  },
  ID: {
    name: "Indonesia",
    alpha2Code: "ID",
    dial_code: "+62",
    country_code: "id_ID",
  },
  CI: {
    name: "Côte d'Ivoire",
    alpha2Code: "CI",
    dial_code: "+225",
    country_code: "fr_CI",
  },
  IR: {
    name: "Iran (Islamic Republic of)",
    alpha2Code: "IR",
    dial_code: "+98",
    country_code: "fa_IR",
  },
  IQ: {
    name: "Iraq",
    alpha2Code: "IQ",
    dial_code: "+964",
    country_code: "ar_IQ",
  },
  IE: {
    name: "Ireland",
    alpha2Code: "IE",
    dial_code: "+353",
    country_code: "en_IE",
  },
  IM: {
    name: "Isle of Man",
    alpha2Code: "IM",
    dial_code: "+44",
    country_code: "en_IM",
  },
  IL: {
    name: "Israel",
    alpha2Code: "IL",
    dial_code: "+972",
    country_code: "he_IL",
  },
  IT: {
    name: "Italy",
    alpha2Code: "IT",
    dial_code: "+39",
    country_code: "it_IT",
  },
  JM: {
    name: "Jamaica",
    alpha2Code: "JM",
    dial_code: "+1876",
    country_code: "en_JM",
  },
  JP: {
    name: "Japan",
    alpha2Code: "JP",
    dial_code: "+81",
    country_code: "ja_JP",
  },
  JE: {
    name: "Jersey",
    alpha2Code: "JE",
    dial_code: "+44",
    country_code: "en_JE",
  },
  JO: {
    name: "Jordan",
    alpha2Code: "JO",
    dial_code: "+962",
    country_code: "ar_JO",
  },
  KZ: {
    name: "Kazakhstan",
    alpha2Code: "KZ",
    dial_code: "+7",
    country_code: "ru_KZ",
  },
  KE: {
    name: "Kenya",
    alpha2Code: "KE",
    dial_code: "+254",
    country_code: "sw_KE",
  },
  KI: {
    name: "Kiribati",
    alpha2Code: "KI",
    dial_code: "+686",
    country_code: "en_KI",
  },
  KP: {
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: "KP",
    dial_code: "+850",
    country_code: "ko_KP",
  },
  KR: {
    name: "Korea (Republic of)",
    alpha2Code: "KR",
    dial_code: "+82",
    country_code: "ko_KR",
  },
  KW: {
    name: "Kuwait",
    alpha2Code: "KW",
    dial_code: "+965",
    country_code: "ar_KW",
  },
  KG: {
    name: "Kyrgyzstan",
    alpha2Code: "KG",
    dial_code: "+996",
    country_code: "ky_KG",
  },
  LA: {
    name: "Lao People's Democratic Republic",
    alpha2Code: "LA",
    dial_code: "+856",
    country_code: "lo_LA",
  },
  LV: {
    name: "Latvia",
    alpha2Code: "LV",
    dial_code: "+371",
    country_code: "lv_LV",
  },
  LB: {
    name: "Lebanon",
    alpha2Code: "LB",
    dial_code: "+961",
    country_code: "ar_LB",
  },
  LS: {
    name: "Lesotho",
    alpha2Code: "LS",
    dial_code: "+266",
    country_code: "en_LS",
  },
  LR: {
    name: "Liberia",
    alpha2Code: "LR",
    dial_code: "+231",
    country_code: "en_LR",
  },
  LY: {
    name: "Libya",
    alpha2Code: "LY",
    dial_code: "+218",
    country_code: "ar_LY",
  },
  LI: {
    name: "Liechtenstein",
    alpha2Code: "LI",
    dial_code: "+423",
    country_code: "de_LI",
  },
  LT: {
    name: "Lithuania",
    alpha2Code: "LT",
    dial_code: "+370",
    country_code: "lt_LT",
  },
  LU: {
    name: "Luxembourg",
    alpha2Code: "LU",
    dial_code: "+352",
    country_code: "fr_LU",
  },
  MO: {
    name: "Macao",
    alpha2Code: "MO",
    dial_code: "+853",
    country_code: "zh_MO",
  },
  MK: {
    name: "Macedonia (the former Yugoslav Republic of)",
    alpha2Code: "MK",
    dial_code: "+389",
    country_code: "mk_MK",
  },
  MG: {
    name: "Madagascar",
    alpha2Code: "MG",
    dial_code: "+261",
    country_code: "fr_MG",
  },
  MW: {
    name: "Malawi",
    alpha2Code: "MW",
    dial_code: "+265",
    country_code: "en_MW",
  },
  MY: {
    name: "Malaysia",
    alpha2Code: "MY",
    dial_code: "+60",
    country_code: "ms_MY",
  },
  MV: {
    name: "Maldives",
    alpha2Code: "MV",
    dial_code: "+960",
    country_code: "dv_MV",
  },
  ML: {
    name: "Mali",
    alpha2Code: "ML",
    dial_code: "+223",
    country_code: "fr_ML",
  },
  MT: {
    name: "Malta",
    alpha2Code: "MT",
    dial_code: "+356",
    country_code: "mt_MT",
  },
  MH: {
    name: "Marshall Islands",
    alpha2Code: "MH",
    dial_code: "+692",
    country_code: "en_MH",
  },
  MQ: {
    name: "Martinique",
    alpha2Code: "MQ",
    dial_code: "+596",
    country_code: "fr_MQ",
  },
  MR: {
    name: "Mauritania",
    alpha2Code: "MR",
    dial_code: "+222",
    country_code: "ar_MR",
  },
  MU: {
    name: "Mauritius",
    alpha2Code: "MU",
    dial_code: "+230",
    country_code: "en_MU",
  },
  YT: {
    name: "Mayotte",
    alpha2Code: "YT",
    dial_code: "+262",
    country_code: "fr_YT",
  },
  MX: {
    name: "Mexico",
    alpha2Code: "MX",
    dial_code: "+52",
    country_code: "es_MX",
  },
  FM: {
    name: "Micronesia (Federated States of)",
    alpha2Code: "FM",
    dial_code: "+691",
    country_code: "en_FM",
  },
  MD: {
    name: "Moldova (Republic of)",
    alpha2Code: "MD",
    dial_code: "+373",
    country_code: "ro_MD",
  },
  MC: {
    name: "Monaco",
    alpha2Code: "MC",
    dial_code: "+377",
    country_code: "fr_MC",
  },
  MN: {
    name: "Mongolia",
    alpha2Code: "MN",
    dial_code: "+976",
    country_code: "mn_MN",
  },
  ME: {
    name: "Montenegro",
    alpha2Code: "ME",
    dial_code: "+382",
    country_code: "sr_ME",
  },
  MS: {
    name: "Montserrat",
    alpha2Code: "MS",
    dial_code: "+1664",
    country_code: "en_MS",
  },
  MA: {
    name: "Morocco",
    alpha2Code: "MA",
    dial_code: "+212",
    country_code: "ar_MA",
  },
  MZ: {
    name: "Mozambique",
    alpha2Code: "MZ",
    dial_code: "+258",
    country_code: "pt_MZ",
  },
  MM: {
    name: "Myanmar",
    alpha2Code: "MM",
    dial_code: "+95",
    country_code: "my_MM",
  },
  NA: {
    name: "Namibia",
    alpha2Code: "NA",
    dial_code: "+264",
    country_code: "en_NA",
  },
  NR: {
    name: "Nauru",
    alpha2Code: "NR",
    dial_code: "+674",
    country_code: "en_NR",
  },
  NP: {
    name: "Nepal",
    alpha2Code: "NP",
    dial_code: "+977",
    country_code: "ne_NP",
  },
  NL: {
    name: "Netherlands",
    alpha2Code: "NL",
    dial_code: "+31",
    country_code: "nl_NL",
  },
  NC: {
    name: "New Caledonia",
    alpha2Code: "NC",
    dial_code: "+687",
    country_code: "fr_NC",
  },
  NZ: {
    name: "New Zealand",
    alpha2Code: "NZ",
    dial_code: "+64",
    country_code: "en_NZ",
  },
  NI: {
    name: "Nicaragua",
    alpha2Code: "NI",
    dial_code: "+505",
    country_code: "es_NI",
  },
  NE: {
    name: "Niger",
    alpha2Code: "NE",
    dial_code: "+227",
    country_code: "fr_NE",
  },
  NG: {
    name: "Nigeria",
    alpha2Code: "NG",
    dial_code: "+234",
    country_code: "en_NG",
  },
  NU: {
    name: "Niue",
    alpha2Code: "NU",
    dial_code: "+683",
    country_code: "en_NU",
  },
  NF: {
    name: "Norfolk Island",
    alpha2Code: "NF",
    dial_code: "+672",
    country_code: "en_NF",
  },
  MP: {
    name: "Northern Mariana Islands",
    alpha2Code: "MP",
    dial_code: "+1670",
    country_code: "en_MP",
  },
  NO: {
    name: "Norway",
    alpha2Code: "NO",
    dial_code: "+47",
    country_code: "no_NO",
  },
  OM: {
    name: "Oman",
    alpha2Code: "OM",
    dial_code: "+968",
    country_code: "ar_OM",
  },
  PK: {
    name: "Pakistan",
    alpha2Code: "PK",
    dial_code: "+92",
    country_code: "ur_PK",
  },
  PW: {
    name: "Palau",
    alpha2Code: "PW",
    dial_code: "+680",
    country_code: "en_PW",
  },
  PS: {
    name: "Palestine, State of",
    alpha2Code: "PS",
    dial_code: "+970",
    country_code: "ar_PS",
  },
  PA: {
    name: "Panama",
    alpha2Code: "PA",
    dial_code: "+507",
    country_code: "es_PA",
  },
  PG: {
    name: "Papua New Guinea",
    alpha2Code: "PG",
    dial_code: "+675",
    country_code: "en_PG",
  },
  PY: {
    name: "Paraguay",
    alpha2Code: "PY",
    dial_code: "+595",
    country_code: "es_PY",
  },
  PE: {
    name: "Peru",
    alpha2Code: "PE",
    dial_code: "+51",
    country_code: "es_PE",
  },
  PH: {
    name: "Philippines",
    alpha2Code: "PH",
    dial_code: "+63",
    country_code: "en_PH",
  },
  PN: {
    name: "Pitcairn",
    alpha2Code: "PN",
    dial_code: "+64",
    country_code: "en_PN",
  },
  PL: {
    name: "Poland",
    alpha2Code: "PL",
    dial_code: "+48",
    country_code: "pl_PL",
  },
  PT: {
    name: "Portugal",
    alpha2Code: "PT",
    dial_code: "+351",
    country_code: "pt_PT",
  },
  PR: {
    name: "Puerto Rico",
    alpha2Code: "PR",
    dial_code: "+1787",
    country_code: "es_PR",
  },
  QA: {
    name: "Qatar",
    alpha2Code: "QA",
    dial_code: "+974",
    country_code: "ar_QA",
  },
  RE: {
    name: "Réunion",
    alpha2Code: "RE",
    dial_code: "+262",
    country_code: "fr_RE",
  },
  RO: {
    name: "Romania",
    alpha2Code: "RO",
    dial_code: "+40",
    country_code: "ro_RO",
  },
  RU: {
    name: "Russian Federation",
    alpha2Code: "RU",
    dial_code: "+7",
    country_code: "ru_RU",
  },
  RW: {
    name: "Rwanda",
    alpha2Code: "RW",
    dial_code: "+250",
    country_code: "rw_RW",
  },
  BL: {
    name: "Saint Barthélemy",
    alpha2Code: "BL",
    dial_code: "+590",
    country_code: "fr_BL",
  },
  SH: {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2Code: "SH",
    dial_code: "+290",
    country_code: "en_SH",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    alpha2Code: "KN",
    dial_code: "+1869",
    country_code: "en_KN",
  },
  LC: {
    name: "Saint Lucia",
    alpha2Code: "LC",
    dial_code: "+1758",
    country_code: "en_LC",
  },
  MF: {
    name: "Saint Martin (French part)",
    alpha2Code: "MF",
    dial_code: "+590",
    country_code: "fr_MF",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    alpha2Code: "PM",
    dial_code: "+508",
    country_code: "fr_PM",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    alpha2Code: "VC",
    dial_code: "+1784",
    country_code: "en_VC",
  },
  WS: {
    name: "Samoa",
    alpha2Code: "WS",
    dial_code: "+685",
    country_code: "sm_WS",
  },
  SM: {
    name: "San Marino",
    alpha2Code: "SM",
    dial_code: "+378",
    country_code: "it_SM",
  },
  ST: {
    name: "Sao Tome and Principe",
    alpha2Code: "ST",
    dial_code: "+239",
    country_code: "pt_ST",
  },
  SA: {
    name: "Saudi Arabia",
    alpha2Code: "SA",
    dial_code: "+966",
    country_code: "ar_SA",
  },
  SN: {
    name: "Senegal",
    alpha2Code: "SN",
    dial_code: "+221",
    country_code: "fr_SN",
  },
  RS: {
    name: "Serbia",
    alpha2Code: "RS",
    dial_code: "+381",
    country_code: "sr_RS",
  },
  SC: {
    name: "Seychelles",
    alpha2Code: "SC",
    dial_code: "+248",
    country_code: "en_SC",
  },
  SL: {
    name: "Sierra Leone",
    alpha2Code: "SL",
    dial_code: "+232",
    country_code: "en_SL",
  },
  SG: {
    name: "Singapore",
    alpha2Code: "SG",
    dial_code: "+65",
    country_code: "zh_SG",
  },
  SX: {
    name: "Sint Maarten (Dutch part)",
    alpha2Code: "SX",
    dial_code: "+1721",
    country_code: "nl_SX",
  },
  SK: {
    name: "Slovakia",
    alpha2Code: "SK",
    dial_code: "+421",
    country_code: "sk_SK",
  },
  SI: {
    name: "Slovenia",
    alpha2Code: "SI",
    dial_code: "+386",
    country_code: "sl_SI",
  },
  SB: {
    name: "Solomon Islands",
    alpha2Code: "SB",
    dial_code: "+677",
    country_code: "en_SB",
  },
  SO: {
    name: "Somalia",
    alpha2Code: "SO",
    dial_code: "+252",
    country_code: "so_SO",
  },
  ZA: {
    name: "South Africa",
    alpha2Code: "ZA",
    dial_code: "+27",
    country_code: "en_ZA",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    alpha2Code: "GS",
    dial_code: "+500",
    country_code: "en_GS",
  },
  SS: {
    name: "South Sudan",
    alpha2Code: "SS",
    dial_code: "+211",
    country_code: "en_SS",
  },
  ES: {
    name: "Spain",
    alpha2Code: "ES",
    dial_code: "+34",
    country_code: "es_ES",
  },
  LK: {
    name: "Sri Lanka",
    alpha2Code: "LK",
    dial_code: "+94",
    country_code: "si_LK",
  },
  SD: {
    name: "Sudan",
    alpha2Code: "SD",
    dial_code: "+249",
    country_code: "ar_SD",
  },
  SR: {
    name: "Suriname",
    alpha2Code: "SR",

    dial_code: "+597",
    country_code: "nl_SR",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    alpha2Code: "SJ",
    dial_code: "+4779",
    country_code: "no_SJ",
  },
  SE: {
    name: "Sweden",
    alpha2Code: "SE",
    dial_code: "+46",
    country_code: "sv_SE",
  },
  CH: {
    name: "Switzerland",
    alpha2Code: "CH",
    dial_code: "+41",
    country_code: "de_CH",
  },
  SY: {
    name: "Syrian Arab Republic",
    alpha2Code: "SY",
    dial_code: "+963",
    country_code: "ar_SY",
  },
  TW: {
    name: "Taiwan",
    alpha2Code: "TW",
    dial_code: "+886",
    country_code: "zh_TW",
  },
  TJ: {
    name: "Tajikistan",
    alpha2Code: "TJ",
    dial_code: "+992",
    country_code: "tg_TJ",
  },
  TZ: {
    name: "Tanzania, United Republic of",
    alpha2Code: "TZ",
    dial_code: "+255",
    country_code: "sw_TZ",
  },
  TH: {
    name: "Thailand",
    alpha2Code: "TH",
    dial_code: "+66",
    country_code: "th_TH",
  },
  TL: {
    name: "Timor-Leste",
    alpha2Code: "TL",
    dial_code: "+670",
    country_code: "pt_TL",
  },
  TG: {
    name: "Togo",
    alpha2Code: "TG",
    dial_code: "+228",
    country_code: "fr_TG",
  },
  TK: {
    name: "Tokelau",
    alpha2Code: "TK",
    dial_code: "+690",
    country_code: "en_TK",
  },
  TO: {
    name: "Tonga",
    alpha2Code: "TO",
    dial_code: "+676",
    country_code: "en_TO",
  },
  TT: {
    name: "Trinidad and Tobago",
    alpha2Code: "TT",
    dial_code: "+1868",
    country_code: "en_TT",
  },
  TN: {
    name: "Tunisia",
    alpha2Code: "TN",
    dial_code: "+216",
    country_code: "ar_TN",
  },
  TR: {
    name: "Turkey",
    alpha2Code: "TR",
    dial_code: "+90",
    country_code: "tr_TR",
  },
  TM: {
    name: "Turkmenistan",
    alpha2Code: "TM",
    dial_code: "+993",
    country_code: "tk_TM",
  },
  TC: {
    name: "Turks and Caicos Islands",
    alpha2Code: "TC",
    dial_code: "+1649",
    country_code: "en_TC",
  },
  TV: {
    name: "Tuvalu",
    alpha2Code: "TV",
    dial_code: "+688",
    country_code: "en_TV",
  },
  UG: {
    name: "Uganda",
    alpha2Code: "UG",
    dial_code: "+256",
    country_code: "sw_UG",
  },
  UA: {
    name: "Ukraine",
    alpha2Code: "UA",
    dial_code: "+380",
    country_code: "uk_UA",
  },
  AE: {
    name: "United Arab Emirates",
    alpha2Code: "AE",
    dial_code: "+971",
    country_code: "ar_AE",
  },
  GB: {
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha2Code: "GB",
    dial_code: "+44",
    country_code: "en_GB",
  },
  UM: {
    name: "United States Minor Outlying Islands",
    alpha2Code: "UM",
    dial_code: "+1",
    country_code: "en_UM",
  },
  US: {
    name: "United States of America",
    alpha2Code: "US",
    dial_code: "+1",
    country_code: "en_US",
  },
  UY: {
    name: "Uruguay",
    alpha2Code: "UY",
    dial_code: "+598",
    country_code: "es_UY",
  },
  UZ: {
    name: "Uzbekistan",
    alpha2Code: "UZ",
    dial_code: "+998",
    country_code: "uz_UZ",
  },
  VU: {
    name: "Vanuatu",
    alpha2Code: "VU",
    dial_code: "+678",
    country_code: "bi_VU",
  },
  VE: {
    name: "Venezuela (Bolivarian Republic of)",
    alpha2Code: "VE",
    dial_code: "+58",
    country_code: "es_VE",
  },
  VN: {
    name: "Viet Nam",
    alpha2Code: "VN",
    dial_code: "+84",
    country_code: "vi_VN",
  },
  VG: {
    name: "Virgin Islands (British)",
    alpha2Code: "VG",
    dial_code: "+1284",
    country_code: "en_VG",
  },
  VI: {
    name: "Virgin Islands (U.S.)",
    alpha2Code: "VI",
    dial_code: "+1 340",
    country_code: "en_VI",
  },
  WF: {
    name: "Wallis and Futuna",
    alpha2Code: "WF",
    dial_code: "+681",
    country_code: "fr_WF",
  },
  EH: {
    name: "Western Sahara",
    alpha2Code: "EH",
    dial_code: "+212",
    country_code: "es_EH",
  },
  YE: {
    name: "Yemen",
    alpha2Code: "YE",
    dial_code: "+967",
    country_code: "ar_YE",
  },
  ZM: {
    name: "Zambia",
    alpha2Code: "ZM",
    dial_code: "+260",
    country_code: "en_ZM",
  },
  ZW: {
    name: "Zimbabwe",
    alpha2Code: "ZW",
    dial_code: "+263",
    country_code: "en_ZW",
  },
};
