/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFilterHeaderData } from "_metronic/layout/core/FilterHeaderData";
import { FC, useEffect } from "react";

const ToolbarAccounting: FC = () => {
  const { filterHeader } = useFilterHeaderData();

  useEffect(() => {
    document.body.setAttribute("data-kt-app-toolbar-fixed", "true");
  }, []);
  return filterHeader ? filterHeader : null;
};

export { ToolbarAccounting };
