export const PERMISSIONS = {
  USER: {
    VIEW_LIST: "list-admin",
    VIEW_DETAIL: "view-admin",
  },

  QA: {
    ANSWER: "answer-question",
  },

  JOURNEY: {
    VIEW_LIST: "list-journey",
  },

  SHOP: {
    VIEW_LIST: "list-location",
    CREATE_IMPORT: "create-import-location",
    UPDATE: "update-location",
    DELETE: "delete-location",
  },

  COUPON: {
    VIEW_LIST: "list-coupon",
    CREATE_IMPORT: "create-import-coupon",
    UPDATE: "update-coupon",
    DELETE: "delete-coupon",
  },

  CAR: {
    VIEW_LIST: "list-journey",
  },

  NOTIFICATION: {
    VIEW_LIST: "list-notification",
    CREATE: "create-notification",
  },

  POLICY: {
    VIEW_LIST: "list-policy",
    CREATE: "create-policy",
    UPDATE: "update-policy",
    DELETE: "delete-policy",
  },

  BANNER: {
    VIEW_LIST: "list-banner",
    CREATE: "create-banner",
    UPDATE: "update-banner",
    DELETE: "delete-banner",
  },

  ADMIN: {
    VIEW_LIST: "list-admin",
    CREATE: "create-admin",
    UPDATE: "update-admin",
    DELETE: "delete-admin",
  },

  ROLE: {
    VIEW_LIST: "list-role",
    CREATE: "create-role",
    UPDATE: "update-role",
    DELETE: "delete-role",
  },
};

export const USER_TYPE = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "1",
    label: "NORMAL",
  },
  {
    value: "2",
    label: "KOL",
  },
  {
    value: "3",
    label: "BIZ",
  },
];

export const PLATFORM = [
  {
    value: "google",
    label: "PLATFORM-GOOGLE",
  },

  {
    value: "apple",
    label: "PLATFORM-APPLE",
  },

  {
    value: "amazon",
    label: "PLATFORM-AMAZON",
  },

  {
    value: "facebook",
    label: "PLATFORM-FACEBOOK",
  },

  {
    value: "naver",
    label: "PLATFORM-NAVER",
  },

  {
    value: "kakao",
    label: "PLATFORM-KAKAO",
  },

  {
    value: "default",
    label: "PLATFORM-PICAVERSE",
  },
];

export const EXTRA_TYPE = [
  {
    value: "charge",
    label: "CHARGE",
  },
  {
    value: "revenue",
    label: "REVENUE",
  },
  {
    value: "gift",
    label: "GIFT",
  },
  {
    value: "bonus",
    label: "BONUS",
  },
  {
    value: "refund",
    label: "REFUND",
  },
  {
    value: "withdrawal",
    label: "WITHDRAWAL",
  },
  {
    value: "buy",
    label: "BUY",
  },
  {
    value: "donate",
    label: "DONATE",
  },
];

export const REQUEST_STATUS = [
  {
    value: "0",
    label: "DRAFT",
  },
  {
    value: "1",
    label: "PROCESSING",
  },
  {
    value: "2",
    label: "APPROVED",
  },
  {
    value: "3",
    label: "REJECT",
  },
];

export const POST_TYPE = [
  {
    value: "free",
    label: "FREE",
  },
  {
    value: "paid",
    label: "PAID",
  },
  {
    value: "limited_edition",
    label: "LIMITED-EDITION",
  },
  {
    value: "photocard",
    label: "PHOTO-CARD",
  },
];

export const POST_SHOW_STATUS = [
  {
    value: "",
    label: "SHOW",
  },

  {
    value: "1",
    label: "DELETED",
  },
];

export const ANNOUNCE_STATUS = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "happening",
    label: "HAPPENING",
  },
  {
    value: "passed",
    label: "PASSED",
  },
  {
    value: "in_active",
    label: "IN_ACTIVE",
  },
];

export const GROUP_PUSH = [
  {
    label: "ALL",
    value: "",
  },
  {
    label: "AUTO",
    value: "auto",
  },
  {
    label: "MANUAL",
    value: "manual",
  },
];

export const REDIREACT_TYPE = [
  {
    label: "DEFAULT",
    value: "default",
  },
  {
    label: "HOME",
    value: "home",
  },
  {
    label: "SEARCH",
    value: "search",
  },
  {
    label: "EXPLORE",
    value: "explore",
  },
  {
    label: "CHAT",
    value: "chat",
  },
];

export const NOTIFICATION_STATUS = [
  {
    value: "waiting",
    label: "NOTIFY.STATUS.WAITING",
  },

  {
    value: "completed",
    label: "NOTIFY.STATUS.COMPLETED",
  },
];

export const NOTIFICATION_DEVICE = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const CS_REPLY = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "1",
    label: "YES",
  },
  {
    value: "0",
    label: "NO",
  },
];

export const ACCOUNT_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const ACCOUNT_TYPE_UPDATE = [
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const NOTIFY_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MANUAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "2", label: "AUTO", color: "gold" },
];

export const OS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const RANKING_SETTING_STATUS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];

export const RANKING_SETTING_EDIT_STATUS_OPTIONS = [
  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];

export const COLLECT_KOL_STATUS = [
  {
    value: "all",
    label: "ALL",
  },
  {
    value: "waiting",
    label: "NOTIFY.STATUS.WAITING",
  },

  {
    value: "complete",
    label: "NOTIFY.STATUS.COMPLETE",
  },
  {
    value: "processing",
    label: "NOTIFY.STATUS.PROCESSING",
  },
];

export const PURCHASE_STATUS_OPTIONS = [
  {
    value: "0",
    label: "INITIATED",
  },
  {
    value: "20",
    label: "CREATE_SUCCESS",
  },
  {
    value: "30",
    label: "CREATE_ERROR",
  },
  {
    value: "35",
    label: "PAY_PENDING",
  },
  {
    value: "40",
    label: "PAY_SUCCESS",
  },
  {
    value: "45",
    label: "PAY_CANCELED",
  },
  {
    value: "50",
    label: "PAY_ERROR",
  },
  {
    value: "60",
    label: "CONFIRM_DELIVERY_SUCCESS",
  },
  {
    value: "70",
    label: "CONFIRM_DELIVERY_ERROR",
  },
  {
    value: "80",
    label: "REFUND_SUCCESS",
  },
  {
    value: "90",
    label: "REFUND_ERROR",
  },
  {
    value: "100",
    label: "PAYMENT_SUCCESS",
  },
];

export const PURCHASE_METHOD_OPTIONS = [
  {
    value: "paypal",
    label: "PayPal",
  },
  {
    value: "in-app",
    label: "In_App",
  },
];

export const GENDER_OPTIONS = [
  {
    value: "0",
    label: "MALE",
  },
  {
    value: "1",
    label: "FEMALE",
  },
  {
    value: "2",
    label: "OTHER",
  },
];

export const EVENT_STATUS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },
  {
    value: "0",
    label: "INACTIVE",
  },
  {
    value: "1",
    label: "ACTIVE",
  },
];

export const EVENT_STATUS_OPTIONS_2 = [
  {
    value: "all",
    label: "ALL",
  },
  {
    value: "not-happen",
    label: "NOT-HAPPEN",
  },
  {
    value: "happening",
    label: "HAPPENING",
  },
  {
    value: "ENDED",
    label: "Ended",
  },
  {
    value: "in_active",
    label: "INACTIVE",
  },
];

export const EVENT_TRANSACTION_STATUS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },
  /* {
    value: "0",
    label: "PENDING",
  }, */
  {
    value: "1",
    label: "COMPLETE",
  },
  {
    value: "2",
    label: "CANCEL",
  },
];
