import { useLang } from "_metronic/i18n/Metronici18n";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import { DatePicker, DatePickerProps, Input, Select } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { ResetComponent } from "app/_components/table/header/ResetButton";
import { RowCountComponent } from "app/_components/table/header/RowsCountComponent";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import DateRangeCustom from "./components/DateRangeCustom";
import SearchCustom from "./components/SearchCustom";
import SelectMultiOptions from "./components/SelectMultiOptions";
import { useState } from "react";

const UsersListHeader = ({
  optionsHeader,
  selectOption,
  loadMoreSelectOption,
  filterKeywordWithApi,
}: any) => {
  const intl = useIntl();
  const { updateState, resetState, state, searchClickAction } =
    useQueryRequest();

  const [maxPage, setMaxPage] = useState<number | undefined>();
  const [openSelect, setOpenSelect] = useState(false);

  const lang = useLang();
  const defaultDate = dayjs().add(-1, "w");
  const defaultMonth = dayjs().add(-1, "month");

  const weekFormat = "MM/DD";
  const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .format(weekFormat)}`;

  const getTranslation = (value: string | undefined) => {
    let result = "";
    try {
      result = intl.formatMessage({ id: value });
    } catch (error) {
      result = value || "";
    }
    return result;
  };

  const renderOptionsHeader = () => {
    return optionsHeader.map((item: any, index: number) => {
      switch (item.id) {
        case "LIMIT":
          return (
            <div key={index} className="card-toolbar align-items-end">
              <RowCountComponent
                updateState={updateState}
                state={state}
                useQueryRequest={useQueryRequest}
              />
            </div>
          );
        case "SEARCH":
          return (
            <SearchCustom
              updateState={updateState}
              state={state}
              key={index}
              placeholder={intl.formatMessage({ id: item.placeholder })}
              searchType={item?.searchType || "search"}
            />
          );

        case "DATE-RANGE":
          return (
            <DateRangeCustom
              state={state}
              updateState={updateState}
              key={index}
              title={intl.formatMessage({ id: item.label })}
            />
          );

        case "DATE-RANGE-RANKING-WEEKLY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>
              <DatePicker
                key={index}
                format={customWeekStartEndFormat}
                picker="week"
                onChange={(value) => {
                  const startWeek = dayjs(value)
                    .startOf("week")
                    .format("YYYY-MM-DD");
                  const endWeek = dayjs(value)
                    .endOf("week")
                    .format("YYYY-MM-DD");

                  updateState({
                    start_date: startWeek,
                    end_date: endWeek,
                  });
                }}
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date).startOf("week")
                    : defaultDate
                }
                defaultValue={defaultDate}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );
        case "DATE-RANGE-RANKING-DAILY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>

              {/* begin::Search */}
              <DatePicker
                onChange={(data, dateString) => {
                  updateState({
                    start_date: dateString,
                    end_date: dateString,
                  });
                }}
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date)
                    : dayjs().add(-1, "day")
                }
                defaultValue={dayjs().add(-1, "day")}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );

        case "DATE-RANGE-RANKING-MONTHLY":
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
              </span>
              <DatePicker
                picker="month"
                value={
                  state.start_date && state.end_date
                    ? dayjs(state.start_date).startOf("month")
                    : defaultMonth
                }
                onChange={(value) => {
                  const startWeek = dayjs(value)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                  const endWeek = dayjs(value)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                  updateState({
                    start_date: startWeek,
                    end_date: endWeek,
                  });
                }}
                defaultValue={defaultMonth}
                disabledDate={(date) => date && date.valueOf() > Date.now()}
                locale={lang === "ko" ? ko : en}
              />
            </div>
          );
        case "MULTI-OPTIONS":
          return (
            <SelectMultiOptions
              state={state}
              updateState={updateState}
              key={index}
              dataOptions={item.dataOptions}
              title={item.title}
              type={item.type}
            />
          );
        case "SELECT-OPTION":
          let dataSelect = selectOption ? selectOption : item.dataOptions;
          let valueSelect =
            selectOption &&
            selectOption.find((elm: { label: any; value: any }) => {
              if (elm?.value.toString() === state[`${item.type_filter_key}`]) {
                return elm.label;
              }
            });
          return (
            <div key={index} className="card-toolbar align-items-center d-flex">
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                {intl.formatMessage({ id: item.label })}
              </span>
              <div className="d-flex align-items-center position-relative">
                <Select
                  // showSearch
                  suffixIcon={null}
                  mode="multiple"
                  filterOption={(inputValue: any, option: any) => {
                    return option?.children
                      ?.toString()
                      ?.concat(option?.value?.toString())
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase());
                  }}
                  onSearch={filterKeywordWithApi && filterKeywordWithApi}
                  //notFoundContent={renderNotFound(selectOption, dataSelect)}
                  // open={openSelect}
                  //onDropdownVisibleChange={() => setOpenSelect(true)}
                  onChange={(value: any) => {
                    updateState({
                      [`${item.type_filter_key}`]:
                        value[value?.length - 1]?.toString(),
                      page: 1,
                    });
                    setOpenSelect(false);
                  }}
                  value={state[`${item.type_filter_key}`]}
                  style={{ minWidth: 150, width: item.width || "auto" }}
                  onPopupScroll={loadMoreSelectOption && loadMoreSelectOption}
                  placeholder={
                    item?.placeholder &&
                    intl.formatMessage({ id: item.placeholder })
                  }
                  onFocus={() => setOpenSelect(true)}
                  onBlur={() => setOpenSelect(false)}
                >
                  {dataSelect?.map((option: any) => (
                    <Select.Option key={option.value}>
                      {selectOption
                        ? option?.label
                        : getTranslation(option.label)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          );
        case "SMALL-NUMBER-INPUT":
          return (
            <div className="d-flex align-items-center">
              {item?.label && (
                <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                  {intl.formatMessage({ id: item.label })}:
                </span>
              )}

              <div
                // key={key}
                className="card-toolbar align-items-end min-w-50px ps-0 small-number-input"
              >
                <Input
                  allowClear
                  className="d-flex form-control bg- form-control-solid ps-0 pe-0 min-w-100 min-w-50px"
                  style={{
                    padding: "0.55rem 0.75rem 0.55rem 0rem !important",
                  }}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  type="number"
                  onChange={(e) =>
                    updateState({
                      ...state,
                      [`${item.type_filter_key}`]: e.target.value.toString(),
                    })
                  }
                />
              </div>
            </div>
          );

        case "MAX-PAGE":
          return (
            <div className="d-flex align-items-center">
              {item?.label && (
                <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
                  {intl.formatMessage({ id: item.label })}:
                </span>
              )}

              <div
                // key={key}
                className="card-toolbar align-items-center min-w-50px ps-0 small-number-input d-flex gap-5"
              >
                <Input
                  allowClear
                  className="d-flex form-control bg- form-control-solid ps-0 pe-0 w-100px"
                  style={{
                    padding: "0.55rem 0.75rem 0.55rem 0rem !important",
                  }}
                  type="number"
                  onChange={(e) => {
                    const value = Number(e?.target?.value);
                    console.log(value);

                    if (value < 0 || value > 100) return;
                    setMaxPage(value);
                  }}
                  id="max-page-input"
                  value={maxPage}
                />

                <div style={{ width: 100 }}>
                  <button
                    type="button"
                    className="btn btn-light fw-bold px-6"
                    onClick={() => {
                      console.log("value", maxPage, item.type_filter_key);
                      updateState({
                        ...state,
                        [`${item.type_filter_key}`]: maxPage,
                      });
                      setMaxPage(undefined);
                    }}
                  >
                    {intl.formatMessage({ id: "CALL" })}
                  </button>
                </div>
              </div>
            </div>
          );

        case "RESET":
          return (
            <div key={index} className="card-toolbar align-items-end">
              <ResetComponent resetState={resetState} />
            </div>
          );
      }
    });
  };

  return (
    <FilterHeader>
      <div className="w-100 px-0 py-3 rounded d-flex align-items-center flex-wrap me-5 filter-header gap-4">
        {renderOptionsHeader()}
        <div className="card-toolbar align-items-end">
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative">
              <button
                type="button"
                className="btn btn-light fw-bold px-6"
                onClick={() => searchClickAction()}
              >
                {intl.formatMessage({ id: "SEARCH" })}
              </button>
              {/* end::Menu */}
            </div>
            {/* end::Search */}
          </div>
        </div>
      </div>
    </FilterHeader>
  );
};

export { UsersListHeader };
