import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { getNotificationLogs } from "./core/_requests";
import TableSummary from "app/_components/coreListData/header/TableSummary";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { NotificationLog } from "./core/_models";
import { PageTitle } from "_metronic/layout/core";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import _ from "lodash";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";

const NotificationLogs = () => {
  const notificationLogs = useQueryResponseData();
  const data = useMemo(() => notificationLogs || [], [notificationLogs]);
  const res = useQueryResponsePagination();
  const intl = useIntl();

  const { pageInstance } = useQueryRequest();
  const cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL-LOG",
      }),
      value: res?.total,
    },
  ];
  //
  // const {
  //   isFetching: isFetchingType,
  //   refetch: refetchType,
  //   data: responseType,
  // } = useQuery(
  //   "all_types",
  //   async () => {
  //     const res = await getNotificationLogsType();
  //     return res;
  //   },
  //   { cacheTime: 20000, keepPreviousData: true, refetchOnWindowFocus: false }
  // );

  function tryParse(value: string) {
    try {
      const parsed = JSON.parse(value);
      if (_.has(parsed, "kr")) {
        _.set(parsed, "ko", _.get(parsed, "kr"));
        _.unset(parsed, "kr");
      }
      return parsed;
    } catch (err) {}
    return value;
  }

  function formatJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const notificationColumns: ReadonlyArray<Column<NotificationLog>> = [
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="ID"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "id",
      accessor: "id",
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="USER"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "user",
      accessor: "user",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        return <UserInfoCell user={row.user} />;
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="TITLE"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "title",
      accessor: "title",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        try {
          const format = formatJsonString(row?.title)
            ? tryParse(row?.title)[intl.locale] || "-"
            : row?.title;
          return <div>{format}</div>;
        } catch (error) {
          return <div>{row.title}</div>;
        }
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="BODY"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "body",
      accessor: "body",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        try {
          const format = formatJsonString(row?.body)
            ? tryParse(row?.body)[intl.locale] || "-"
            : row?.body;
          return <div>{format}</div>;
        } catch (error) {
          return <div>{row.body}</div>;
        }
      },
    },

    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="TYPE"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "type",
      accessor: "type",
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="CREATED-AT"
          className="d-flex min-w-100px"
          useQueryRequest={useQueryRequest}
          isNoSort={false}
          isCenter={true}
          isForceDesc={true}
        />
      ),
      id: "created_at",
      accessor: "created_at",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        return <DateTimeCell date={row.created_at} />;
      },
    },
  ];

  const optionsFilterHeader: any = OPTION_KEYS_HEADER.NOTIFICATION_LOG;

  // useEffect(() => {
  //   if (
  //     responseType &&
  //     responseType.data?.length &&
  //     !optionsFilterHeader.some((option: any) => option.type === "type")
  //   ) {
  //     const TYPE_OPTIONS = responseType.data.map((i: any) => {
  //       return {
  //         value: i.id,
  //         label: intl.formatMessage({
  //           id: i.screen.toUpperCase(),
  //         }),
  //       };
  //     });
  //     optionsFilterHeader.push({
  //       id: "MULTI-OPTIONS",
  //       title: "ACTION-TYPE",
  //       type: "type",
  //       dataOptions: TYPE_OPTIONS,
  //     });
  //   }
  // }, [responseType]);

  // useEffect(() => {
  //   refetchType();
  // }, [refetchType]);

  return (
    <div key={pageInstance}>
      <PageTitle>
        {intl.formatMessage({ id: "MENU.NOTIFICATION-LOG" })}
      </PageTitle>
      <KTCard>
        {/* {responseType && responseType.data && (
          <UsersListHeader optionsHeader={optionsFilterHeader} />
        )} */}

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <TableSummary cardList={cardList} />
        </div>
        <Table
          defineColumns={notificationColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const NotificationLogWrapper = () => (
  <TableWrapper getPanigation={getNotificationLogs}>
    <NotificationLogs />
  </TableWrapper>
);

export default NotificationLogWrapper;
