import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_LIST_EVENT_URL = `${API_URL}/events`;

export const getPaginationOnGoingEvent = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);

  if (!newquery.is_expired) {
    newquery.is_expired = 0;
  }

  if (!newquery.order_by) {
    newquery.sort_by = "start_date";
    newquery.order_by = "DESC";
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_LIST_EVENT_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};
