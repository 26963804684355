/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { KTIcon, initialQueryState } from "_metronic/helpers";
import { useIntl } from "react-intl";

const ResetComponent = ({ useQueryRequest, resetData, resetState }: any) => {
  const intl = useIntl();

  return (
    <div className="card-title">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        <button
          type="button"
          className="btn btn-light fw-bold px-6"
          onClick={() => resetState(resetData)}
        >
          {intl.formatMessage({ id: "RESET" })}
        </button>
        {/* end::Menu */}
      </div>
      {/* end::Search */}
    </div>
  );
};

export { ResetComponent };
