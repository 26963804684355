// @ts-nocheck
import { KTIcon, subString } from "_metronic/helpers";
import { Popconfirm } from "antd";
import Fancybox from "app/_components/Fancybox";
import { useIntl } from "react-intl";

import { CircularProgress } from "@mui/material";
import { Tag, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { randomAntdColor } from "app/utils";
import { useState } from "react";
// import { delete_admin, revert_post } from "../core/_requests";
// import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import TooltipWrapper from "app/_components/TooltipWrapper";
import { delete_admin } from "app/_modules/content-management/pages/article/core/_requests";

const _listPostsOfUserColumn: any = (updateRow: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='STATUS'
        className='min-w-100px text-start'
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "is_active",
    accessor: "is_active",
    isLeftRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const intl = useIntl();

      return row?.is_deleted === 1 ? (
        <span className='badge badge-pill badge-danger'>{intl.formatMessage({ id: "DELETED" })}</span>
      ) : (
        <span className='badge badge-pill badge-success'>{intl.formatMessage({ id: "SHOW" })}</span>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='POST'
        className='min-w-100px text-start'
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "action",
    isLeftRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <RenderPostInfo row={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='TYPE'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "cost",
    accessor: "cost",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <div>{row.cost > 0 ? "Paid" : "Free"}</div>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='HASHTAG'
        className='min-w-250px'
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "hashtag",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      return (
        <div className='d-flex flex-wrap justify-content-center'>
          {row?.hashtag?.map((i: any, index: number) => (
            <Tag style={{ marginTop: 10 }} bordered={false} color={randomAntdColor(index)} key={i}>
              {i}
            </Tag>
          ))}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='DONATIONS_COUNT'
        className='min-w-50px'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "donations_count",
    accessor: "donations_count",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='FILE_COUNT'
        className='min-w-50px'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "file_count",
    accessor: "file_count",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='PAID_POST_COUNT'
        className='min-w-50px'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "paid_posts_count",
    accessor: "paid_posts_count",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='TOTAL_DONATED_COUNT'
        className='min-w-50px'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "total_donated_cost",
    accessor: "total_donated_cost",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='COMMENT_COUNT'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "comment_count",
    accessor: "comment_count",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <div
          className={` ${row.comment_count > 0 ? "cursor-pointer text-primary" : ""}`}
          onClick={() => updateRow(row, "comment_count")}
        >
          {row.comment_count}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='CREATED-AT'
        className='min-w-100px'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='UPDATED-AT'
        className='min-w-100x'
        useQueryRequest={useQueryRequest}
        // isNoSort={false}
        isCenter={true}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.updated_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='ACTION'
        className=''
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action-delete",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      if (row?.is_deleted) return <RevertComponent row={row} />;
      return <DeleteComponent row={row} />;
    },
  },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteArticle, setIsLoadingDeleteArticle] = useState(false);
  const handleDeleteArticle = async () => {
    setIsLoadingDeleteArticle(true);
    const res = await delete_admin(row.id);
    setIsLoadingDeleteArticle(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE.ARTICLE-SUCCESS" }),
      });
      refetch();
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "ARTICLE.DELETE.CONFIRM" })}
        onConfirm={handleDeleteArticle}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
        disabled={row.is_deleted}
      >
        <button className='btn btn-sm btn-icon btn-bg-light btn-active-light-primary'>
          {isLoadingDeleteArticle ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon iconName='trash' className='fs-2' />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

function RevertComponent({ row }: any) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingRevertArticle, setIsLoadingRevertArticle] = useState(false);
  const handleDeleteArticle = async () => {
    setIsLoadingRevertArticle(true);
    const res = await revert_post(row.id);
    setIsLoadingRevertArticle(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "REVERT-SUCCESS" }),
      });
      refetch();
    }
  };
  return (
    <button className='btn btn-sm btn-icon btn-bg-light btn-active-light-primary'>
      <Popconfirm
        title={intl.formatMessage({ id: "RESTORE" })}
        description={intl.formatMessage({ id: "ARTICLE.RESTORE.CONFIRM" })}
        onConfirm={handleDeleteArticle}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className='btn btn-sm btn-icon btn-bg-light btn-active-light-primary'>
          {isLoadingRevertArticle ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon iconType='solid' iconName='arrow-circle-left' className='fs-2' />
          )}
        </button>
      </Popconfirm>
    </button>
  );
}

const RenderPostInfo = ({ row }) => {
  let media = [];

  if (row.files.length === 0) media = [];
  else
    media = row.files?.map((i) => {
      return {
        type: i?.type,
        url: i?.url,
      };
    });

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
        key={Math.random()}
      >
        <div style={{ display: "inline-block", maxWidth: 70 }}>
          <div className='post_img'>
            {media?.map(({ type, url }, index) => (
              <div key={index} className='symbol symbol-70px me-3 position-relative'>
                {index == 0 ? (
                  <div className='symbol-label overflow-hidden'>
                    <a data-fancybox='gallery' href={url} key={Math.random()}>
                      {type === "image" ? (
                        <img alt='post' src={url} style={{ maxHeight: 70, maxWidth: 70 }} />
                      ) : (
                        <video alt='post' src={url} style={{ maxHeight: 70, maxWidth: 70 }} />
                      )}
                    </a>
                  </div>
                ) : (
                  <div style={{ display: "none" }}>
                    <a data-fancybox='gallery' href={url} key={Math.random()}>
                      <img alt='post' src={url} width='70' height='70' />
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Fancybox>

      <div className='d-flex flex-column ms-3'>
        <TooltipWrapper text={row.contents} limit={20}>
          <div
            className='text-gray-800 mb-1 text-break text-hover-primary'
            style={{ whiteSpace: "normal", maxWidth: 300, minWidth: 200 }}
          >
            {subString(row.contents, 20)}
          </div>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export { _listPostsOfUserColumn };
