import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import {
  getAllAppPermissions,
  getMyPermissions,
  getUserByToken,
} from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  myPermission: any[];
  allPermission: any[];
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  myPermission: [],
  allPermission: [],
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [myPermission, setMyPermission] = useState<any[]>([]);
  const [allPermission, setAllPermission] = useState<any[]>([]);
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  const getAllPermissions = async () => {
    const res = await getAllAppPermissions();
    const allPermissions: any = [];
    if (res?.data?.code == 200) {
      res?.data?.data.forEach((item: any) => {
        if (item.child) {
          item.child.forEach((childItem: any) => {
            if (childItem.permissions) {
              allPermissions.push(...childItem.permissions);
            }
          });
        }
      });

      setAllPermission(allPermissions);
    }
  };

  useEffect(() => {
    async function getPermission() {
      getAllPermissions();
      const res = await getMyPermissions();
      if (res?.data?.code == 200) {
        const permission = res.data?.data;
        setMyPermission(permission);
      }
    }
    if (auth) {
      getPermission();
    }
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        myPermission,
        allPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, currentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        const data = JSON.parse(
          localStorage.getItem("kt-auth-react-v") || "{}"
        );
        if (data) {
          setCurrentUser(data.user);
        }
        // if (!didRequest.current) {
        //   const {data} = await getUserByToken(apiToken)
        //   if (data) {
        //     setCurrentUser(data)
        //   }
        // }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth) {
      requestUser(auth.api_token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
