import { Button, Form, Input } from "antd";
import { useIntl } from "react-intl";
import { KTCard } from "_metronic/helpers";
import { updateBusinessStatus } from "../core/_requests";
import { useEffect, useState } from "react";

const Actions = (props: any) => {
  const intl = useIntl();

  const { status, userID, fetchData } = props;
  const handleChangeStatus = async (status: any) => {
    await updateBusinessStatus(userID, status);
    fetchData();
  };
  return (
    <div className='d-flex flex-center' style={{ gap: 5 }}>
      {status == 0 ? (
        <>
          <Button type='primary' size='large' onClick={() => handleChangeStatus(1)}>
            {intl.formatMessage({
              id: "APPROVE",
            })}
          </Button>
          <Button size='large' danger onClick={() => handleChangeStatus(2)}>
            {intl.formatMessage({
              id: "REJECT",
            })}
          </Button>
        </>
      ) : (
        <Button size='large' disabled>
          {intl.formatMessage({
            id: status == 1 ? "NOTIFY.STATUS.APPROVED" : "NOTIFY.STATUS.REJECTED",
          })}
        </Button>
      )}
    </div>
  );
};

function OverviewTab(props: any) {
  const intl = useIntl();
  const { user, fetchData } = props;

  const getCompanyType = (key: any) => {
    switch (key) {
      case 1:
        return "개인사업자";
      case 2:
        return "영리 법인";
      case 3:
        return "비영리 법인";
      case 4:
        return "단체";
      default:
        break;
    }
  };

  const renderFormItems = () => {
    const items = JSON.parse(JSON.stringify(user?.profile));

    delete items?.score;
    delete items?.comment;
    delete items?.hashtag;
    delete items?.out_links;
    delete items?.bg_image_url;
    delete items?.company_type;
    delete items?.documentary;

    const keys = Object.keys(items);
    return keys.map((key) => {
      const label = intl.formatMessage({
        id: `BIZ.${key.toUpperCase()}`,
      });
      return (
        <Form.Item key={key} label={label}>
          <Input defaultValue={user?.profile?.[key]} disabled />
        </Form.Item>
      );
    });
  };

  return (
    <KTCard>
      <div
        style={{
          padding: "2rem 2.25rem",
        }}
      >
        <Form name='basic' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} autoComplete='off'>
          <Form.Item
            label={intl.formatMessage({
              id: `BIZ.COMPANY_TYPE`,
            })}
          >
            <Input defaultValue={getCompanyType(user?.profile?.company_type)} disabled />
          </Form.Item>
          {renderFormItems()}
          <Form.Item
            label={intl.formatMessage({
              id: `BIZ.DOCUMENTARY`,
            })}
          >
            <img
              src={user?.profile?.documentary}
              style={{
                width: "100%",
                aspectRatio: 1,
              }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: `BIZ.LINK`,
            })}
          >
            <Input defaultValue={user?.profile?.out_links?.[0]} disabled />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: `BIZ.YOUTUBE`,
            })}
          >
            <Input defaultValue={user?.profile?.out_links?.[1]} disabled />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: `BIZ.INSTAGRAM`,
            })}
          >
            <Input defaultValue={user?.profile?.out_links?.[2]} disabled />
          </Form.Item>
        </Form>

        <Actions userID={user?.id} status={user?.business_status} fetchData={fetchData} />
      </div>
    </KTCard>
  );
}

export default OverviewTab;
