import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import AllRank from "./all-rank";
import BestRank from "./best-rank";
import MonthlyRank from "./monthly-rank";
import WeeklyRank from "./weekly-rank";

import { PageTitle } from "_metronic/layout/core";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import dayjs from "dayjs";

const RankingPage = () => {
  const intl = useIntl();

  const [tab, setTab] = useState<string>("All");

  return (
    <>
      <PageTitle>
        {intl.formatMessage({
          id: "RANKING",
        })}
      </PageTitle>
      <div className="card card-custom">
        <div
          className="card-header card-header-stretch overflow-auto"
          style={{ justifyContent: "center" }}
        >
          <ul
            className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
            role="tablist"
            style={{ width: "100%" }}
          >
            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "All",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setTab("All");
                }}
                role="tab"
              >
                {intl.formatMessage({
                  id: "ALL",
                })}
              </div>
            </li>

            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Best",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setTab("Best");
                }}
                role="tab"
              >
                {intl.formatMessage({
                  id: "BY-DAY",
                })}
              </div>
            </li>

            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Weekly",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setTab("Weekly");
                }}
                role="tab"
              >
                {intl.formatMessage({
                  id: "BY-WEEK",
                })}
              </div>
            </li>

            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Monthly",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => setTab("Monthly")}
                role="tab"
              >
                {intl.formatMessage({
                  id: "BY-MONTH",
                })}
              </div>
            </li>
          </ul>
        </div>
      </div>

      {tab == "All" && <AllRank />}
      {tab == "Best" && <BestRank />}
      {tab == "Weekly" && <WeeklyRank />}
      {tab == "Monthly" && <MonthlyRank />}
    </>
  );
};

export default RankingPage;
