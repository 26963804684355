// @ts-nocheck
import { Button } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { useIntl } from "react-intl";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { updateStatusConnect } from "../../core/_requests";
import Link from "antd/es/typography/Link";

const ConnectSocialColumns: any = (hasPermissionUpdate: boolean) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='STATUS'
        className='min-w-100px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "status",
    accessor: "status",
    isLeftRow: true,
    Cell: ({ ...props }) => {
      const status = props?.row?.original?.status;
      return <Status status={status} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='CHANNEL'
        className='min-w-125px'
        // isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    // isCenterRow: true,
    isLeftRow: true,
    id: "channel",
    accessor: "channel",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <>{row?.resource}</>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='LINK'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "link",
    accessor: "link",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return (
        <Link href={row?.url} target='_blank'>
          <span className='fw-normal' style={{ marginLeft: "4px" }}>
            {row?.url}
          </span>
        </Link>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='CONTENT-COUNT'
        className='min-w-125px'
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "total",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <p>{`${row?.count_complete}/${row?.total}`}</p>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='CREATED-AT'
        className='min-w-125px'
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <DateTimeCell date={row.created_at} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='LAST-PUBLISHED-DATE'
        className='min-w-125px'
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "last_published_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <DateTimeCell date={row.last_published_at} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='ACTION'
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <Actions {...row} />;
    },
  },
];

const Status = ({ status }: any) => {
  const intl = useIntl();
  console.log("status", status);

  let badgeColor = "warning";
  let label = "NOTIFY.STATUS.WAITING";

  if (status == 1) {
    badgeColor = "success";
    label = "NOTIFY.STATUS.APPROVED";
  }

  if (status == 2) {
    badgeColor = "danger";
    label = "NOTIFY.STATUS.REJECTED";
  }

  return (
    <span
      className={`badge rounded-pill m-auto badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}
    >
      {intl.formatMessage({ id: label })}
    </span>
  );
};

const Actions = (props: any) => {
  const intl = useIntl();
  const { refetch } = useQueryResponse();

  const { resource, user_id, url, status } = props;
  const handleChangeStatus = async (status) => {
    const requestBody = {
      resource: resource,
      user_id: user_id,
      url: url,
      status: status,
    };
    await updateStatusConnect(requestBody);
    refetch();
  };
  return (
    <div className='d-flex text-end' style={{ gap: 5 }}>
      <Button size='large' onClick={() => handleChangeStatus(1)} disabled={status != 0}>
        {intl.formatMessage({
          id: "APPROVE",
        })}
      </Button>
      <Button size='large' danger onClick={() => handleChangeStatus(2)} disabled={status != 0}>
        {intl.formatMessage({
          id: "REJECT",
        })}
      </Button>
    </div>
  );
};

export { ConnectSocialColumns };
