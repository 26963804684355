import { toAbsoluteUrl } from "_metronic/helpers";
import { useIntl } from "react-intl";
interface EmptyChartProps {
  className?: string;
  height?: number;
  isHiddenIcon?: boolean;
  isShowDescription?: boolean;
}
export default function EmptyChart({
  height,
  isHiddenIcon = false,
  isShowDescription = true,
}: EmptyChartProps) {
  const intl = useIntl();
  return (
    <div
      className="w-100 h-xxl-[150] d-flex h-100"
      style={{
        height: "100%",
        minHeight: height ? height : 150,

        // backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!isHiddenIcon && (
        <img
          src={toAbsoluteUrl("/media/empty/empty-file.svg")}
          alt=""
          style={{ maxHeight: 120 }}
        />
      )}

      <h2 className="d-flex text-center w-100 align-content-center justify-content-center text-gray-600 fw-bold align-middle fs-6 gy-5  pt-8">
        {intl.formatMessage({ id: "EMPTY.TITLE" })}
      </h2>
      {isShowDescription && (
        <p style={{ fontSize: 11, color: "#b5b5c3" }}>
          {intl.formatMessage({ id: "EMPTY.DESCRIPTION" })}
        </p>
      )}
    </div>
  );
}
