import axios, { AxiosResponse } from "axios";
import { UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/posts`;
const GET_USERS_LIKE_POST_URL = `${API_URL}/articles/user-like`;
const GET_USERS_COMMENT_POST_URL = `${API_URL}/articles/user-comment`;

const getUsers = (query: any): Promise<UsersQueryResponse> => {
  return (
    axios
      .get(`${GET_USERS_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.list;
        const pagination = d.data.meta;
        const articleType = d.data.data.articleType;

        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(pagination?.page, pagination?.total, pagination?.limit),
            total: pagination?.total,
          },
          articleType: articleType,
        };
      })
  );
};

const edit_user = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/update`, body);
};

const delete_admin = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}/delete`);
};

const delete_post = (postIds: any) => {
  return axios.delete(`${GET_USERS_URL}/delete`, {
    data: {
      postIds,
    },
  });
};

const revert_post = (id: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/restore`);
};

export const getPaginationUserLikePost = async (query: any, id: any) => {
  const newquery = applyDefaultQueryConditions(query);

  // if (!newquery.order_by) {
  //   newquery.sort_by = "view";
  //   newquery.order_by = "DESC";
  // }
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_LIKE_POST_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

const getUsersLikePost = (query: any) => {
  const newquery = { ...query };
  return (
    axios
      .get(`${GET_USERS_LIKE_POST_URL}`, {
        params: newquery,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.pagination;
        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(pagination?.page, pagination?.total, pagination?.limit),
            total: pagination?.total,
          },
        };
      })
  );
};

const getUsersCommentPost = (query: any) => {
  return (
    axios
      .get(`${GET_USERS_COMMENT_POST_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.pagination;
        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(pagination?.page, pagination?.total, pagination?.limit),
            total: pagination?.total,
          },
        };
      })
  );
};

const getCategories = async (query: any) => {
  const res = await callApiWithTryCatch(
    async () =>
      await axios.get(`${API_URL}/categories`, {
        params: { page: 1, limit: 100, sort_by: "created_at", order_by: "ASC" },
      })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform } = res.data;
  return list;
};

const updatePost = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}`, body);
};

export const getPaginationPosts = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  // if (!newquery.is_active) {
  //   newquery.is_active = false;
  // }
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
    user_platform: user_platform,
  };
};

export {
  getUsers,
  edit_user,
  delete_admin,
  getUsersCommentPost,
  revert_post,
  getUsersLikePost,
  delete_post,
  getCategories,
  updatePost,
};
