import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getPaginationUserLog = async (query: any, param: any) => {
  const newquery = applyDefaultQueryConditions(query);

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${API_URL}/user-logs`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
  };
};
