import { callApiWithTryCatch } from "app/utils/apiUntils";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/users`;

export const getPanigationUsers = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  newquery.is_deleted = 1
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

const restore_user = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/restore`, body);
};

export { restore_user };
