import { useIntl } from "react-intl";

export default function LanguageTabItem({
  title,
  titlePlaceholder,
  data,
  onChangeData,
  hasError,
  content,
  contentPlaceholder,
  lang = "ko",
}: any) {
  const intl = useIntl();
  return (
    <>
      <div className="fv-row mb-3">
        <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
          <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required">
            {intl.formatMessage({ id: title })}
          </span>
        </label>
        <textarea
          className="form-control form-control-lg form-control-solid min-h-150px"
          name="appname"
          placeholder={intl.formatMessage({
            id: titlePlaceholder,
          })}
          // value={data?.title}
          required
          onChange={(e) => onChangeData(e.target.value, "title", lang)}
          autoFocus
        />
        {hasError?.name && (
          <div className="fv-plugins-message-container">
            <div
              data-field="appname"
              data-validator="notEmpty"
              className="fv-help-block"
            >
              {hasError?.name}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
