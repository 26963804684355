/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import { DatePicker, Select, Tabs, TabsProps } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import { NOTIFICATION_DEVICE } from "app/constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import UploadImg from "./UploadImg";
import CustomLanguageTabItem from "./CustomLanguageTabItem";
import { Typography } from "@mui/material";

dayjs.extend(customParseFormat);

const Step1 = ({ data, updateData, error, updateError }: any) => {
  const intl = useIntl();
  const lang = useLang();

  const defaultForm = {
    title: [] as any,
    body: [] as any,
    device_type: "all",
    file: null,
    image: "",
    start_date: "",
    end_date: "",
  };

  const onChangeTitleAndBody = (value: any, type: string, lang: string) => {
    if (type === "title") {
      const existTitle =
        formData.title &&
        formData.title.find((item: any) => item.lang === lang);
      if (existTitle) {
        const newFormData = {
          ...formData,
        };
        newFormData.title &&
          newFormData.title.map((item: any) => {
            if (item.lang === lang) {
              item.text = value;
            }
          });
        setFormData(newFormData);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          title: [...formData.title, { lang, text: value }],
        }));
      }
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
          {intl.formatMessage({ id: "ENGLISH" })}
        </Typography>
      ),
      children: (
        <CustomLanguageTabItem
          lang={"en"}
          title={"TITLE"}
          titlePlaceholder="PUSH-NOTICE.TITLE.PLACEHOLDER"
          data={data}
          onChangeData={onChangeTitleAndBody}
          hasError={error}
          content="CONTENT"
          contentPlaceholder="TYPE-CONTENT"
        />
      ),
    },
    {
      key: "2",
      label: (
        <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
          {intl.formatMessage({ id: "KOREAN" })}
        </Typography>
      ),
      children: (
        <CustomLanguageTabItem
          lang={"ko"}
          title={"TITLE"}
          titlePlaceholder="PUSH-NOTICE.TITLE.PLACEHOLDER"
          data={data}
          onChangeData={onChangeTitleAndBody}
          hasError={error}
          content="CONTENT"
          contentPlaceholder="TYPE-CONTENT"
        />
      ),
    },
  ];

  const [formData, setFormData] = useState(defaultForm);

  const updateFormData = (fieldsToUpdate: any) => {
    setFormData((prev) => ({ ...prev, ...fieldsToUpdate }));
  };

  useEffect(() => {
    updateData(formData);
  }, [formData]);

  useEffect(() => {
    return () => {
      updateData(defaultForm);
      updateError(null);
    };
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column flex-sm-row current row"
        data-kt-stepper-element="content"
      >
        <div className="col-12 col-sm-6 col-lg-8">
          <div className="fv-row mb-3">
            <Tabs defaultActiveKey="1" items={items} />
          </div>
          <div className="d-flex flex-column flex-lg-row gap-4">
            <div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required">
                {intl.formatMessage({ id: "EVENT-START-AT" })}:{" "}
              </span>
              <DatePicker
                locale={lang === "ko" ? ko : en}
                format={"YYYY-MM-DD HH:mm"}
                value={formData.start_date ? dayjs(formData.start_date) : null}
                showTime={{ format: "HH:mm" }}
                onChange={(data, date) => {
                  updateFormData({
                    start_date: date,
                  });
                }}
                status={error?.start_date ? "error" : ""}
              />
            </div>

            <div>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required">
                {intl.formatMessage({ id: "EVENT-END-AT" })}:{" "}
              </span>
              <DatePicker
                locale={lang === "ko" ? ko : en}
                format={"YYYY-MM-DD HH:mm"}
                value={formData.end_date ? dayjs(formData.end_date) : null}
                showTime={{ format: "HH:mm" }}
                disabledDate={(date) =>
                  date && date.valueOf() <= dayjs(formData.start_date).valueOf()
                }
                onChange={(data, date) => {
                  updateFormData({
                    end_date: date,
                  });
                }}
                status={error?.end_date ? "error" : ""}
              />
            </div>
          </div>
          <div className="mt-2">
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required">
              {intl.formatMessage({ id: "DEVICES" })}:{" "}
            </span>
            <div className="d-flex align-items-center position-relative event-date-picker">
              <Select
                onChange={(value: any) => {
                  updateFormData({
                    device_type: value.toString(),
                  });
                }}
                value={formData.device_type}
                defaultValue={NOTIFICATION_DEVICE[0].value}
                options={NOTIFICATION_DEVICE.map((i: any) => {
                  return {
                    ...i,
                    label: intl.formatMessage({ id: i.label }),
                  };
                })}
                // style={{ height: 40, width: 100 }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 position-relative mt-3 mt-sm-13">
          <UploadImg error={error?.image} updateFormData={updateFormData} />
        </div>
      </div>
    </>
  );
};

export { Step1 };
