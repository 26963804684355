import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../_modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "app/_modules/auth";
import { App } from "../App";
import {
  PERMISSIONS_MAP,
  dataMappingRouteAndPermission,
  hasPermission,
} from "_metronic/helpers";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser, myPermission, allPermission } = useAuth();
  const hasPermissionViewDashboard = hasPermission(
    PERMISSIONS_MAP.VIEW_DASHBOARD,
    myPermission,
    allPermission
  );

  const firstHasPermission =
    myPermission &&
    myPermission.find(
      (perm: any) => perm.name.includes("list") || perm.name.includes("summary")
    );

  const pathNavigation = dataMappingRouteAndPermission.find(
    (item) =>
      item.key === firstHasPermission?.name.replace("-", "_").toUpperCase()
  );

  const navigationDefault = !!hasPermissionViewDashboard
    ? "/dashboard"
    : pathNavigation?.value || "/dashboard";

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              {myPermission && (
                <Route index element={<Navigate to={navigationDefault} />} />
              )}
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
