import { Spin } from "antd";
import { useIntl } from "react-intl";

const UsersListLoading = ({ colSpan }: any) => {
  const intl = useIntl();
  const styles = {
    borderRadius: "0.475rem",
    // boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    // backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "auto",
    width: "100%",
    minHeight: "10rem",
    padding: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <tr>
      <td colSpan={colSpan}>
        <div style={{ ...styles, textAlign: "center" }}>
          <Spin />
          {/* {intl.formatMessage({ id: "LOADING.PROGRESS" })} */}
        </div>
      </td>
    </tr>
  );
};

export { UsersListLoading };
