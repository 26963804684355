interface ApiResponse {
  statusCode: number;
  data: any;
  message: string;
  title?: string;
}

export async function callApiWithTryCatch<T>(
  apiFunction: () => Promise<T>,
  setLoadingCallback?: (isLoading: boolean) => void
): Promise<ApiResponse> {
  try {
    setLoadingCallback && setLoadingCallback(true);
    const response: any = await apiFunction();
    return {
      data: response.data.data,
      message: response.data.message,
      title: response.data?.title ? response.data.title : "",
      statusCode: response.data.statusCode,
    };
  } catch (error: any) {
    return {
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      title: error?.response?.data?.title,
      data: null,
    };
  } finally {
    setLoadingCallback && setLoadingCallback(false);
  }
}
