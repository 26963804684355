/* eslint-disable jsx-a11y/anchor-is-valid */
import { QUERIES, toAbsoluteUrl } from "_metronic/helpers";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import ChipPoint from "app/_components/point/ChipPoint";
import ToolTipRanking from "app/_components/tooltips/ToolTipRanking";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUsersRanking } from "./core/_requests";

type Props = {
  className: string;
};

const TopUserTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl();

  const [type, setType] = React.useState("date");
  const navigate = useNavigate();
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.USERS_RANKING}`],
    () => {
      return getUsersRanking(type);
    },
    { cacheTime: 20000, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const data = response?.list.map((item, index) => ({
    ...item,
    img_rank: `/media/ranking/${item.rank}.svg`,
  }));

  useEffect(() => {
    refetch();
  }, [type]);

  return (
    <div className={`card {className} h-100`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 d-flex">
            {intl.formatMessage({ id: "TOP-USERS" })}
            <ToolTipRanking />
          </span>

          <span className="text-muted fw-semibold fs-7">
            {" "}
            {intl.formatMessage({ id: "TOP-USERS-DES" })}
          </span>
        </h3>
        <div className="card-toolbar" data-kt-buttons="true">
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              type === "date" ? "active" : ""
            }`}
            id="kt_charts_widget_3_year_btn"
            onClick={() => setType("date")}
          >
            {intl.formatMessage({ id: "DAY" })}
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              type === "week" ? "active" : ""
            }`}
            id="kt_charts_widget_3_month_btn"
            onClick={() => setType("week")}
          >
            {intl.formatMessage({ id: "WEEK" })}
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              type === "month" ? "active" : ""
            }`}
            id="kt_charts_widget_3_week_btn"
            onClick={() => setType("month")}
          >
            {intl.formatMessage({ id: "MONTH" })}
          </a>
        </div>
      </div>
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive min-h-450px mh-700px overflow-scroll">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4 position-relative">
            {/* begin::Table head */}
            <thead className="sticky-top">
              <tr className="fw-bold text-muted bg-light sticky-top">
                <th className="ps-4 min-w-150px rounded-start sticky-top">
                  {" "}
                  {intl.formatMessage({ id: "USER" })}
                </th>

                <th className="min-w-50px text-center sticky-top">
                  {" "}
                  {intl.formatMessage({ id: "POINT" })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {isFetching && (
                <>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-5">
                          <span
                            style={{ marginRight: 24 }}
                            className="placeholder bg-secondary rounded w-30px h-30px"
                          ></span>
                          <span className="placeholder bg-secondary rounded w-150px h-30px"></span>
                        </div>
                      </div>
                    </td>

                    <td className="text-center">
                      <span className="placeholder bg-secondary rounded-pill col-1 w-55px h-30px"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-5">
                          <span
                            style={{ marginRight: 24 }}
                            className="placeholder bg-secondary rounded w-30px h-30px"
                          ></span>
                          <span className="placeholder bg-secondary rounded w-150px h-30px"></span>
                        </div>
                      </div>
                    </td>

                    <td className="text-center">
                      <span className="placeholder bg-secondary rounded-pill col-1 w-55px h-30px"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-5">
                          <span
                            style={{ marginRight: 24 }}
                            className="placeholder bg-secondary rounded w-30px h-30px"
                          ></span>
                          <span className="placeholder bg-secondary rounded w-150px h-30px"></span>
                        </div>
                      </div>
                    </td>

                    <td className="text-center">
                      <span className="placeholder bg-secondary rounded-pill col-1 w-55px h-30px"></span>
                    </td>
                  </tr>
                </>
              )}
              {!isFetching &&
                data &&
                data.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="me-5">
                          {item.img_rank ? (
                            <img
                              src={toAbsoluteUrl(item.img_rank)}
                              className="h-75 align-self-end"
                              alt=""
                            />
                          ) : (
                            <div style={{ width: 36, height: 36 }}></div>
                          )}
                        </div>
                        <div className="symbol symbol-50px me-5">
                          <span className="symbol-label bg-light rounded-circle overflow-hidden">
                            <div
                              className={`align-self-end my-auto ${
                                item.avatar ? "h-100 w-100" : "h-30px w-30px"
                              }`}
                              style={{
                                backgroundImage: `url(${
                                  item.avatar
                                    ? item.avatar
                                    : "/media/svg/avatars/blank.svg"
                                })`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                              }}
                            ></div>
                            {/* <img
                              src={toAbsoluteUrl(
                                item.avatar
                                  ? item.avatar
                                  : "/media/svg/avatars/blank.svg"
                              )}
                              className="align-self-end my-auto "
                              alt=""
                              height={item.avatar ? "100%" : 30}
                              width={item.avatar ? "100%" : 30}
                            /> */}
                          </span>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                            onClick={() => {
                              navigate(
                                `/user-management/users/active/detail/${item.id}`
                              );
                            }}
                          >
                            {item.name}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {item.email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        className={`text-center text-dark fw-bold text-hover-primary d-flex m-auto align-items-center fs-6 ${
                          item.score > 9999 ? "w-100px" : "w-75px"
                        }`}
                      >
                        <ChipPoint point={item.score} />
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {!isFetching && data && data.length <= 0 ? (
            <div className="card-body py-3 w-100 min-h-350px d-flex align-items-center">
              <EmptyChart />
            </div>
          ) : null}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TopUserTable };
