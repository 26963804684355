import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseData,
  useQueryResponse,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { data } from "jquery";
import { usersColumns } from "./table/_columns";
import { getPaginationExpiredEvent } from "./core/_requests";
import TableWrapper from "app/_components/coreListData";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo } from "react";
import TableSummary from "app/_components/coreListData/header/TableSummary";
import { useIntl } from "react-intl";

const ExpiredEvents = (isTriggerListEvent: any) => {
  const users = useQueryResponseData();
  const { refetch } = useQueryResponse();
  const data = useMemo(() => users, [users]);
  const res = useQueryResponsePagination();
  const intl = useIntl();

  const cardList = [
    {
      title: intl.formatMessage({
        id: "Event",
      }),
      value: res?.total,
    },
  ];

  useEffect(() => {
    refetch();
  }, [isTriggerListEvent]);

  return (
    <>
      <div /* key={pageInstance} */>
        <KTCard>
          <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.EVENTS} />
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <TableSummary cardList={cardList} />
          </div>
          <Table
            defineColumns={usersColumns}
            data={data}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
          />
        </KTCard>
      </div>
    </>
  );
};

const ExpiredEventsWrapper = (isTriggerListEvent: any) => (
  <TableWrapper getPanigation={getPaginationExpiredEvent}>
    <ExpiredEvents isTriggerListEvent={isTriggerListEvent} />
  </TableWrapper>
);

export default ExpiredEventsWrapper;
