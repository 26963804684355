/* eslint-disable react-hooks/exhaustive-deps */
import { QUERIES, WithChildren } from "_metronic/helpers";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useQueryRequest } from "./QueryRequestProvider";
import {
  getAppDownloadsReport,
  getContentsReport,
  getLikeAndCommentReport,
  getMembersReport,
  getMultiUserActiveViewsReport,
  getRealTimeUserOnlineReport,
  // getUserActive,
  // getVideoViewsReport,
  // getVideosReport,
} from "./_requests";
import { boolean } from "yup";
import {
  RecordChart,
  VideoChart,
  MemberChart,
  AppDownloadChart,
  VideoViewsChart,
  MultiUserActiveChart,
  ContentChart,
  RealTimeChart,
  LikeAndCommentChart,
} from "./_models";

export type ResponseActiveUser = {
  data?: Array<RecordChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseVideosReport = {
  data?: VideoChart[];
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseMembersReport = {
  data?: MemberChart[];
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseAppDownloadsReport = {
  data?: Array<AppDownloadChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseVideoViewsReport = {
  data?: Array<VideoViewsChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseMultiUserActiveReport = {
  data?: Array<MultiUserActiveChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseContentsReport = {
  data?: Array<ContentChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseRealTimeUserOnlineReport = {
  data?: Array<RealTimeChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type ResponseLikeAndCommentReport = {
  data?: Array<LikeAndCommentChart>;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
  };
};

export type QueryActiveUserResponseContextProps = {
  // response?: ResponseActiveUser | undefined;
  // refetch: () => void;
  // refetchRealTimeUserActiveReport: () => void;
  // isLoading: boolean;
  // isLoadingVideosChart: boolean;
  isLoadingMembersChart: boolean;
  isLoadingContentsReportChart: boolean;
  // isLoadingViewsChart: boolean;
  // isLoadingRealTimeChart: boolean;
  // isLoadingAppDownloadsChart: boolean;
  // isLoadingMultiUserActiveReport: boolean;
  isLoadingLikeAndCommentChart: boolean;
  query?: string;
  queryFilterChart?: object;
  // responseVideosReport?: ResponseVideosReport | undefined;
  responseMembersReport?: ResponseMembersReport | undefined;
  responseContentsReport?: ResponseContentsReport | undefined;
  responseAppDownloadsReport?: ResponseAppDownloadsReport | undefined;
  // responseVideoViewsReport?: ResponseVideoViewsReport | undefined;
  responseMultiUserActiveReport?: ResponseMultiUserActiveReport | undefined;
  // responseRealTimeUserActiveReport?:
  //   | ResponseRealTimeUserOnlineReport
  //   | undefined;
  responseLikeAndCommentReport?: ResponseLikeAndCommentReport | undefined;
};

export const initialQueryActiveUserResponse = {
  // response: undefined,
  // refetch: () => {},
  refetchRealTimeUserActiveReport: () => {},
  // isLoading: false,
  queryFilterChart: {},
  responseVideosReport: undefined,
  responseMembersReport: undefined,
  responseContentsReport: undefined,
  // responseAppDownloadsReport: undefined,
  responseVideoViewsReport: undefined,
  responseMultiUserActiveReport: undefined,
  // responseRealTimeUserActiveReport: undefined,
  responseLikeAndCommentReport: undefined,
  isLoadingLikeAndCommentChart: undefined,
};

const QueryResponseContext = createContext<QueryActiveUserResponseContextProps>(
  {
    // response: undefined,
    // refetch: () => {},
    // refetchRealTimeUserActiveReport: () => {},
    // isLoading: false,
    // isLoadingVideosChart: false,
    isLoadingContentsReportChart: false,
    isLoadingMembersChart: false,
    // isLoadingViewsChart: false,
    // isLoadingRealTimeChart: false,
    // isLoadingAppDownloadsChart: false,
    // isLoadingMultiUserActiveReport: false,
    isLoadingLikeAndCommentChart: false,
    queryFilterChart: {},
    // responseVideosReport: undefined,
    responseMembersReport: undefined,
    responseContentsReport: undefined,
    responseAppDownloadsReport: undefined,
    // responseVideoViewsReport: undefined,
    responseMultiUserActiveReport: undefined,
    // responseRealTimeUserActiveReport: undefined,
    responseLikeAndCommentReport: undefined,
  }
);
interface QueryResponseProviderProps {
  children?: ReactNode;
  isSummaryPage?: boolean;
  isArticleSummary?: boolean;
}

const QueryResponseProvider: FC<QueryResponseProviderProps> = ({
  children,
  isSummaryPage = false,
  isArticleSummary = false,
}) => {
  const { state } = useQueryRequest();
  const [queryFilterChart, setQueryFilterChart] =
    useState<QueryActiveUserResponseContextProps["queryFilterChart"]>();
  const updatedQuery = useMemo(() => {
    let customize_users_query = {
      ...(state.start_date && { start_date: state.start_date }),
      ...(state.end_date && { end_date: state.end_date }),

      // ...(state.type && { type: state.type }),
    };

    return customize_users_query;
  }, [state]);

  // const {
  //   isFetching,
  //   refetch,
  //   data: response,
  // } = useQuery(
  //   [`${QUERIES.USERS_LIST}-${queryFilterChart}`, queryFilterChart],
  //   () => {
  //     return getUserActive(queryFilterChart);
  //   },
  //   {
  //     cacheTime: 0,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     enabled: !!queryFilterChart && !isSummaryPage && !isArticleSummary,
  //   }
  // );

  // const { isFetching: isLoadingVideosChart, data: responseVideosReport } =
  //   useQuery(
  //     [`${QUERIES.VIDEOS_REPORT}-${queryFilterChart}`, queryFilterChart],
  //     () => {
  //       return getVideosReport(queryFilterChart);
  //     },
  //     {
  //       cacheTime: 0,
  //       keepPreviousData: false,
  //       refetchOnWindowFocus: false,
  //       enabled:
  //         (!!queryFilterChart && !isSummaryPage) ||
  //         (!!queryFilterChart && isArticleSummary),
  //     }
  //   );

  const {isFetching: isLoadingContentsReportChart, data: responseContentsReport } = useQuery(
    [`${QUERIES.CONTENTS_REPORT}-${queryFilterChart}`, queryFilterChart],
    () => {
      return getContentsReport(queryFilterChart);
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!queryFilterChart && !isSummaryPage,
    }
  );

  const { isFetching: isLoadingMembersChart, data: responseMembersReport } =
    useQuery(
      [`${QUERIES.MEMBERS_REPORT}-${queryFilterChart}`, queryFilterChart],
      () => {
        return getMembersReport(queryFilterChart);
      },
      {
        cacheTime: 0,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        enabled: !!queryFilterChart && !isArticleSummary,
      }
    );

  // const {
  //   isFetching: isLoadingAppDownloadsChart,
  //   data: responseAppDownloadsReport,
  // } = useQuery(
  //   [`${QUERIES.APP_DOWNLOADS_REPORT}-${queryFilterChart}`, queryFilterChart],
  //   () => {
  //     return getAppDownloadsReport(queryFilterChart);
  //   },
  //   {
  //     cacheTime: 0,
  //     keepPreviousData: false,
  //     refetchOnWindowFocus: false,
  //     enabled: !!queryFilterChart && !isSummaryPage && !isArticleSummary,
  //   }
  // );

  // const { isFetching: isLoadingViewsChart, data: responseVideoViewsReport } =
  //   useQuery(
  //     [`${QUERIES.VIDEO_VIEWS_REPORT}-${queryFilterChart}`, queryFilterChart],
  //     () => {
  //       return getVideoViewsReport(queryFilterChart);
  //     },
  //     {
  //       cacheTime: 0,
  //       keepPreviousData: false,
  //       refetchOnWindowFocus: false,
  //       enabled:
  //         (!!queryFilterChart && !isSummaryPage) ||
  //         (!!queryFilterChart && isArticleSummary),
  //     }
  //   );

  // const {
  //   isFetching: isLoadingMultiUserActiveReport,
  //   data: responseMultiUserActiveReport,
  // } = useQuery(
  //   [
  //     `${QUERIES.MULTI_USER_ACTIVE_REPORT}-${queryFilterChart}`,
  //     queryFilterChart,
  //   ],
  //   () => {
  //     return getMultiUserActiveViewsReport(queryFilterChart);
  //   },
  //   {
  //     cacheTime: 0,
  //     keepPreviousData: false,
  //     refetchOnWindowFocus: false,
  //     enabled: !!queryFilterChart && !isArticleSummary,
  //   }
  // );

  // const {
  //   isFetching: isLoadingRealTimeChart,
  //   refetch: refetchRealTimeUserActiveReport,
  //   data: responseRealTimeUserActiveReport,
  // } = useQuery(
  //   [`${QUERIES.REALTIME_USER_ACTIVE_REPORT}`],
  //   () => {
  //     return getRealTimeUserOnlineReport({ limit: 7 });
  //   },
  //   {
  //     cacheTime: 0,
  //     keepPreviousData: false,
  //     refetchOnWindowFocus: false,
  //     enabled: !!queryFilterChart && !isArticleSummary,
  //   }
  // );
  useEffect(() => {
    if (queryFilterChart !== updatedQuery) {
      setQueryFilterChart(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching: isLoadingLikeAndCommentChart,
    refetch: refetchLikeAndCommentReport,
    data: responseLikeAndCommentReport,
  } = useQuery(
    [
      `${QUERIES.LIKE_AND_COMMENT_REPORT}-${queryFilterChart}`,
      queryFilterChart,
    ],
    () => {
      return getLikeAndCommentReport(queryFilterChart);
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!queryFilterChart && !isSummaryPage && !isArticleSummary,
    }
  );
  useEffect(() => {
    if (queryFilterChart !== updatedQuery) {
      setQueryFilterChart(updatedQuery);
    }
  }, [updatedQuery]);

  return (
    <QueryResponseContext.Provider
      value={{
        // isLoading: isFetching,
        // isLoadingVideosChart,
        isLoadingMembersChart,
        isLoadingContentsReportChart,
        // isLoadingViewsChart,
        // isLoadingRealTimeChart,
        // isLoadingMultiUserActiveReport,
        // isLoadingAppDownloadsChart,
        // refetch,
        // response,
        queryFilterChart,
        // responseVideosReport,
        responseContentsReport,
        responseMembersReport,
        // responseAppDownloadsReport,
        // responseVideoViewsReport,
        // responseMultiUserActiveReport,
        // responseRealTimeUserActiveReport,
        // refetchRealTimeUserActiveReport,
        responseLikeAndCommentReport,
        isLoadingLikeAndCommentChart,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
// const useQueryResponseData = () => {
//   const { response } = useQueryResponse();

//   if (!response) {
//     return [];
//   }
//   return response?.data || [];
// };

const useQueryVideosReportResponse = () => useContext(QueryResponseContext);

// const useQueryVideoReportResponseData = () => {
//   const { responseVideosReport } = useQueryVideosReportResponse();

//   if (!responseVideosReport) {
//     return [];
//   }
//   return responseVideosReport?.data || [];
// };

const useQueryMembersReportResponse = () => useContext(QueryResponseContext);

const useQueryMembersReportResponseData = () => {
  const { responseMembersReport } = useQueryMembersReportResponse();

  if (!responseMembersReport) {
    return [];
  }
  return responseMembersReport?.data || [];
};

// const useQueryResponseLoading = (): boolean => {
//   const { isLoading } = useQueryResponse();
//   return isLoading;
// };

const useQueryContentsReportResponse = () => useContext(QueryResponseContext);

const useQueryContentsReportResponseData = () => {
  const { responseContentsReport } = useQueryContentsReportResponse();

  if (!responseContentsReport) {
    return [];
  }
  return responseContentsReport?.data || [];
};

const useQueryAppDownloadsReportResponse = () =>
  useContext(QueryResponseContext);

const useQueryAppDownloadsReportResponseData = () => {
  const { responseAppDownloadsReport } = useQueryAppDownloadsReportResponse();
  if (!responseAppDownloadsReport) {
    return [];
  }
  return responseAppDownloadsReport?.data || [];
};

const useQueryVideoViewsReportResponse = () => useContext(QueryResponseContext);

// const useQueryVideoViewsReportResponseData = () => {
//   const { responseVideoViewsReport } = useQueryVideoViewsReportResponse();
//   if (!responseVideoViewsReport) {
//     return [];
//   }
//   return responseVideoViewsReport?.data || [];
// };

const useQueryMultiUserActiveReportResponse = () =>
  useContext(QueryResponseContext);

const useQueryMultiUserActiveReportResponseData = () => {
  const { responseMultiUserActiveReport } =
    useQueryMultiUserActiveReportResponse();
  if (!responseMultiUserActiveReport) {
    return [];
  }
  return responseMultiUserActiveReport?.data || [];
};

const useQueryRealTimeUserActiveReportResponse = () =>
  useContext(QueryResponseContext);

// const useQueryRealTimeUserActiveReportResponseData = () => {
//   const { responseRealTimeUserActiveReport } =
//     useQueryRealTimeUserActiveReportResponse();
//   if (!responseRealTimeUserActiveReport) {
//     return [];
//   }
//   return responseRealTimeUserActiveReport?.data || [];
// };

const useQueryLikeAndCommentReportResponse = () =>
  useContext(QueryResponseContext);

const useQueryLikeAndCommentReportResponseData = () => {
  const { responseLikeAndCommentReport } =
    useQueryLikeAndCommentReportResponse();
  if (!responseLikeAndCommentReport) {
    return [];
  }
  return responseLikeAndCommentReport?.data || [];
};
export {
  QueryResponseProvider,
  useQueryAppDownloadsReportResponseData,
  useQueryMembersReportResponse,
  useQueryContentsReportResponse,
  useQueryContentsReportResponseData,
  useQueryMembersReportResponseData,
  useQueryMultiUserActiveReportResponse,
  useQueryResponse,
  // useQueryResponseData,
  // useQueryResponseLoading,
  // useQueryVideoReportResponseData,
  // useQueryVideoViewsReportResponseData,
  useQueryVideosReportResponse,
  useQueryMultiUserActiveReportResponseData,
  // useQueryRealTimeUserActiveReportResponseData,
  useQueryLikeAndCommentReportResponseData,
  useQueryLikeAndCommentReportResponse,
};
