// @ts-nocheck
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import moment from "moment";
import { useIntl } from "react-intl";
import { Column } from "react-table";
import { toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        className="min-w-15px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "_id",
    accessor: "_id",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "email",
    accessor: "email",
    Cell: ({ ...props }) => {
      const row = props.row.original;

      return <UserInfoCell user={row.user} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="IP"
        className="min-w-15px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "ip",
    accessor: "ip",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="METHOD"
        className="min-w-15px"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    isCenterRow: true,
    id: "method",
    accessor: "method",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PATH"
        className="min-w-15px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "path",
    accessor: "path",
  },

  /* {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="BODY"
        className="min-w-15px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "body",
    accessor: "body",
  }, */

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LOG-TIME"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },
];

export { usersColumns };
