import { useEffect, useMemo, useRef, useState } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { UsersListLoading } from "./loading/UsersListLoading";
import { Pagination } from "./pagination/Pagination";
import { KTCardBody } from "_metronic/helpers";
import { useIntl } from "react-intl";
import Draggable from "./Draggable";
import { useSticky } from "react-table-sticky";

const Table = ({
  className,
  defineColumns,
  data,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryRequest,
  customRowPropsFunc,
  isDisabledDraggable = false,
}: any) => {
  const isLoading = useQueryResponseLoading();
  const columns = useMemo(() => defineColumns, [defineColumns]);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const tableRef = useRef<any>(null);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      // useBlockLayout,
      useSticky
    );

  const intl = useIntl();

  const handleScroll = () => {
    const table = tableRef.current;
    if (table) {
      const { scrollWidth, scrollLeft, clientWidth } = table;
      setIsScrollAtEnd(scrollWidth - scrollLeft === clientWidth);
    }
  };

  useEffect(() => {
    const table = tableRef.current;
    if (table) {
      table.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (table) {
        table.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [data]);

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive" ref={tableRef}>
        <Draggable isDisabled={isDisabledDraggable}>
          <table
            id="kt_table_users"
            className={`table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer ${
              !isScrollAtEnd && className
            }`}
            {...getTableProps()}
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {headers.map((column: ColumnInstance<any>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            {isLoading && (
              <tbody>
                <UsersListLoading colSpan={headers.length} />
              </tbody>
            )}
            {!isLoading && (
              <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
                {rows.length > 0 &&
                  rows.map((row: Row<any>, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        customRowPropsFunc={customRowPropsFunc}
                      />
                    );
                  })}
              </tbody>
            )}
          </table>
        </Draggable>

        {!isLoading && rows.length === 0 && (
          <div className="d-flex text-center w-100 align-content-center justify-content-center text-gray-600 fw-bold table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer pt-8">
            {intl.formatMessage({ id: "TABLE.NOT-FOUND-DATA" })}
          </div>
        )}
      </div>
      <Pagination
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryResponseLoading={useQueryResponseLoading}
        useQueryRequest={useQueryRequest}
      />
    </KTCardBody>
  );
};

export { Table };
