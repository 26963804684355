// @ts-nocheck
import Column from "antd/es/table/Column";
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";

type Props = {
  row: Row<any>;
  className?: string;
  customRowPropsFunc?: any;
};

const CustomRow: FC<Props> = ({ row, className = "", customRowPropsFunc }) => {
  const customFunctionValue = customRowPropsFunc ? customRowPropsFunc(row) : {};

  return (
    <tr {...row.getRowProps()} className={`${className}`}>
      {row.cells.map((cell, index) => {
        return (
          <td
            key={index}
            {...cell.getCellProps()}
            // className={clsx({
            //   "text-end min-w-100px": cell.column.id === "actions",
            // })}
            {...customFunctionValue}
          >
            {cell?.column?.isCenterRow ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {cell.render("Cell")}
              </div>
            ) : (
              <>{cell.render("Cell")}</>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export { CustomRow };
