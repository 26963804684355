import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import Expired from "./expired";
import Ongoing from "./on-going";

const EventPage = () => {
  const intl = useIntl();

  const [tab, setTab] = useState<string>("Ongoing");

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: "EVENT" })}</PageTitle>
      {/* <PageTitle>
        {intl.formatMessage({
          id: "RANKING",
        })}
      </PageTitle> */}

      <div className="card card-custom">
        <div
          className="card-header card-header-stretch overflow-auto"
          style={{ justifyContent: "center" }}
        >
          <ul
            className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
            role="tablist"
            style={{ width: "100%" }}
          >
            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Ongoing",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setTab("Ongoing");
                }}
                role="tab"
              >
                {intl.formatMessage({
                  id: "ONGOING",
                })}
              </div>
            </li>

            <li
              className="nav-item"
              style={{ justifyContent: "center", flex: 1 }}
            >
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === "Expired",
                })}
                style={{
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setTab("Expired");
                }}
                role="tab"
              >
                {intl.formatMessage({
                  id: "EXPIRED",
                })}
              </div>
            </li>
          </ul>
        </div>
      </div>

      {tab == "Ongoing" && <Ongoing />}
      {tab == "Expired" && <Expired />}
    </>
  );
};

export default EventPage;
