import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { UsersTable } from "../table/UsersTable";

export default function ListShareUserProfile() {
  const intl = useIntl();
  const params = useParams();

  return (
    <div className="card min-h-300px">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#fff",
            m: "auto",
            p: 4,
            border: "0 !important",
            outline: "none",
          }}
        >
          <Box sx={{ overflow: "auto", height: "90%" }}>
            <UsersTable params={params} type="list-share" />
          </Box>
        </Box>
      </div>
    </div>
  );
}
