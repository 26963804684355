import React, { ReactNode } from "react";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";

interface Props {
  children: ReactNode;
  params?: any;
  module?: string;
  getPanigation?: (query: any, custom: any) => Promise<any>;
}
const TableWrapper = ({ children, module, params, getPanigation }: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider
        module={module}
        params={params && params}
        getPanigationData={getPanigation}
      >
        {React.Children.only(children)}
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export default TableWrapper;
