/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
// import { create_admin, edit_admin } from "../_requests";
import {
  Box,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { notification } from "antd";
import { useIntl } from "react-intl";
import { createNewNotification } from "../_requests";
import { Step1 } from "./steps/Step1";

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({
  show,
  handleClose,
  info,
  triggerListNotify,
}: any) => {
  const intl = useIntl();

  const [data, setData] = useState<any>();
  const [hasError, setHasError] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const updateData = (fieldsToUpdate: any) => {
    setData(fieldsToUpdate);
  };

  const isShowToolTip = () => {
    let result = true;
    const dataClone = { ...data };
    if (
      dataClone &&
      dataClone?.title &&
      dataClone?.body &&
      dataClone?.title.length > 1 &&
      dataClone?.body.length > 1
    ) {
      dataClone?.title.map((item: any) => {
        if (
          (item?.lang === "en" || item?.lang === "ko") &&
          item?.text.length > 0
        ) {
          result = false;
        } else {
          result = true;
        }
      });
      dataClone?.body.map((item: any) => {
        if (
          (item?.lang === "en" || item?.lang === "ko") &&
          item?.text.length > 0
        ) {
          result = false;
        } else {
          result = true;
        }
      });
    }

    return result;
  };

  const handleSubmit = async () => {
    try {
      setIsLoadingCreate(true);
      const res: any = await createNewNotification(data);
      if (res.code === 200) {
        notification.success({
          message: (
            <Box>
              <Typography
                fontWeight={700}
                fontSize={16}
                color={"black"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                {data.title.length > 15
                  ? data.title.substring(0, 15) + "..."
                  : data.title}

                {data.device_type === "all" ? (
                  <Chip
                    sx={{ height: 20 }}
                    label={"All"}
                    color="success"
                    variant="outlined"
                  />
                ) : (
                  <img
                    alt="logo"
                    height={20}
                    width={20}
                    src={toAbsoluteUrl(
                      data.device_type === "android"
                        ? "/media/svg/brand-logos/android.svg"
                        : "/media/svg/brand-logos/apple-black.svg"
                    )}
                  />
                )}
              </Typography>
              <Typography fontWeight={500} fontSize={12} color={"gray"}>
                {intl.formatMessage({ id: "DATE" })}: {data.reservation_at}
              </Typography>
              <Typography fontSize={14} fontWeight={700} color={"gray"}>
                {data.body.length > 50
                  ? data.body.substring(0, 50) + "..."
                  : data.body}
              </Typography>
            </Box>
          ),
          duration: 3,
          //style: { backgroundColor: "#f1416c", color: "white" },
        });
      }
      setIsLoadingCreate(false);
      triggerListNotify();
    } catch (error) {
      setIsLoadingCreate(false);
      notification.error({
        message: "error",
        duration: 3,
        //style: { backgroundColor: "#f1416c", color: "white" },
      });
    }
    handleClose();
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px "
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{intl.formatMessage({ id: "PUSH-NOTICE" })}</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div className="px-lg-5 w-full">
            {/*begin::Form */}
            <form noValidate id="kt_modal_create_app_form">
              <Step1
                data={data}
                updateData={updateData}
                hasError={hasError}
                info={info}
              />

              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous"
                    //onClick={prevStep}
                  >
                    <KTIcon iconName="arrow-left" className="fs-3 me-1" />{" "}
                    Previous
                  </button>
                </div>
                <div>
                  <Tooltip
                    placement="top-end"
                    arrow
                    title={
                      isShowToolTip() ? (
                        <Typography>
                          You need to add more title and content for two
                          languages
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  >
                    <div className="cursor-pointer">
                      {isLoadingCreate ? (
                        <CircularProgress />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          data-kt-stepper-action="next"
                          disabled={
                            !data?.title ||
                            !data?.body ||
                            !data?.device_type ||
                            isShowToolTip()
                          }
                          onClick={handleSubmit}
                        >
                          {intl.formatMessage({ id: "PUSH" })}
                        </button>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
