/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Rating } from "@mui/material";
import {
  KTIcon,
  PERMISSIONS_MAP,
  hasPermission,
  toAbsoluteUrl,
} from "_metronic/helpers";
import { Rate, Spin, notification } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { submitReplyCS } from "../core/_requests";
import { useAuth } from "app/_modules/auth";

type Props = {
  className: string;
  item?: any;
  triggerListCS: () => void;
};

const FeedsWidget3: React.FC<Props> = ({ className, item, triggerListCS }) => {
  const intl = useIntl();
  const [replyData, setReplyData] = React.useState({
    reviewId: item.review_id,
    replyText: "",
  });

  const { allPermission, myPermission } = useAuth();

  const hasPermissionReplyCS = hasPermission(
    PERMISSIONS_MAP.REPLY_CS,
    myPermission,
    allPermission
  );

  const [isShowReply, setIsShowReply] = React.useState(
    item?.reply_text ? false : true
  );

  const [isShowInputReply, setIsShowInputReply] = React.useState(false);
  const [isLoadingSendReply, setIsLoadingSendReply] = React.useState(false);

  const handleSubmitReplyCS = async () => {
    if (!replyData.replyText) {
      return;
    }
    setIsLoadingSendReply(true);
    try {
      const res: any = await submitReplyCS(replyData);
      if (res && res.code === 200 && res.message === "Successful.") {
        triggerListCS();
        setIsShowReply(false);
        notification.success({
          message: "Reply successfully",
          duration: 3,
        });
      }
      setIsLoadingSendReply(false);
    } catch (error) {
      setIsLoadingSendReply(false);
      notification.error({
        message: `Error : ${error}`,
        duration: 3,
      });
    }
  };

  return (
    <div className={`card ${className}`}>
      <div className="card-body pb-0">
        <div className="d-flex align-items-center mb-3">
          <div className="d-flex align-items-center flex-grow-1">
            <div className="symbol symbol-45px me-5 rounded-circle overflow-hidden">
              <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
            </div>

            <div className="d-flex flex-column">
              <a
                href="#"
                className="text-gray-800 text-hover-primary fs-6 fw-bold"
              >
                {item.user_name}
              </a>
              <span className="text-gray-400 fw-semibold fs-7">
                {item.review_date}
              </span>
            </div>
          </div>
          <div className="my-0 mt-n12 me">
            <Rate rootClassName="fs-7" value={item?.rating || 0} disabled />
          </div>
        </div>

        <div className="mb-7">
          <div className="text-gray-800 mb-5">{item.review_text}</div>
        </div>

        <div className="separator mb-4"></div>
        {item.reply_text ? (
          <div className="mb-7 ps-10">
            <div className="d-flex mb-5">
              <div className="symbol symbol-45px me-5">
                <img
                  src={toAbsoluteUrl("/media/logos/picaverse.svg")}
                  alt=""
                  className="h-auto"
                />
              </div>

              <div className="d-flex flex-column flex-row-fluid">
                <div className="d-flex align-items-center flex-wrap mb-1">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold me-2"
                  >
                    {intl.formatMessage({ id: "ADMIN" })}
                  </a>

                  <span className="text-gray-400 fw-semibold fs-7">
                    {item.reply_date}
                  </span>
                  {!isShowInputReply && hasPermissionReplyCS ? (
                    <a
                      href="#"
                      className="ms-auto text-gray-400 text-hover-primary fw-semibold fs-7"
                      onClick={() => setIsShowInputReply(true)}
                    >
                      {intl.formatMessage({ id: "EDIT" })}
                    </a>
                  ) : null}
                </div>

                <span className="text-gray-800 fs-7 fw-normal pt-1">
                  {item.reply_text}
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {(!item.reply_text && hasPermissionReplyCS) ||
        (isShowInputReply && hasPermissionReplyCS) ? (
          <form
            className={`position-relative mb-4 ${
              isShowInputReply && item.reply_text ? "border-top pt-4 " : ""
            }`}
          >
            <textarea
              autoFocus
              className="form-control border-0 p-0 pe-10 resize-none min-h-25px"
              rows={1}
              placeholder="Reply.."
              defaultValue={
                isShowInputReply ? item.reply_text : replyData.replyText
              }
              onChange={(event) => {
                setReplyData({ ...replyData, replyText: event.target.value });
              }}
            ></textarea>

            <div className="position-absolute top-0 end-0 me-n5">
              <span
                className="btn btn-icon btn-sm btn-active-color-primary pe-0 me-4"
                onClick={handleSubmitReplyCS}
              >
                {isLoadingSendReply ? (
                  <Spin />
                ) : (
                  <KTIcon
                    iconName={`${
                      replyData.replyText &&
                      replyData.replyText !== (isShowReply && item.reply_text)
                        ? "message-text-2"
                        : ""
                    }`}
                    className="fs-3 mb-2"
                  />
                )}
              </span>
            </div>
          </form>
        ) : null}
      </div>
      {isShowInputReply && item.reply_text ? (
        <Button
          variant="outlined"
          color="error"
          sx={{ width: 60, textTransform: "none", ml: "auto", mb: 2, mr: 2 }}
          onClick={() => setIsShowInputReply(false)}
        >
          {intl.formatMessage({ id: "CANCEL" })}
        </Button>
      ) : null}
    </div>
  );
};

export { FeedsWidget3 };
