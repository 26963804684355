export default function DateTimeCell({ date }: any) {
  const dateString = date && date.split(" ")[0];
  const timeString = date && date.split(" ")[1];
  return (
    <div className="d-flex flex-column align-items-start">
      <div className="text-gray-800 dw-bold d-block mb-1 fs-7">
        {dateString}
      </div>
      <div className="text-muted fw-semibold text-muted d-block fs-7">
        {timeString}
      </div>
    </div>
  );
}
