import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageTitle } from "_metronic/layout/core";
import UsersPage from "./pages/users-list";
import DeletedUser from "./pages/deleted-users";
import { useIntl } from "react-intl";
import UsersSummaryPage from "./pages/summary/UsersSummaryPage";
import ProfilePage from "app/_modules/profile/ProfilePage";
import ListShareUserProfile from "../profile/components/ListShareUserProfile";
import ListPostUserProfile from "../profile/components/ListPostUserProfile";
import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";
import ListUserLogWrapper from "./pages/user-log";

const UserModule = () => {
  const intl = useIntl();

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="summary"
          element={
            <>
              {/* <PageTitle>
                {intl.formatMessage({ id: "MENU.USER-MANAGEMENT.SUMMARY" })}
              </PageTitle> */}
              <QueryRequestProvider>
                <QueryResponseProvider isSummaryPage>
                  <UsersSummaryPage />
                </QueryResponseProvider>
              </QueryRequestProvider>
            </>
          }
        />
        <Route
          path="users/active"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "MENU.USER-MANAGEMENT.ACTIVE-USERS",
                })}
              </PageTitle>
              <UsersPage />
            </>
          }
        />
        <Route
          path="users/deleted"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "MENU.USER-MANAGEMENT.DELETED-ACCOUNTS",
                })}
              </PageTitle>
              <DeletedUser />
            </>
          }
        />

        <Route
          path="user-log"
          element={
            <>
              <PageTitle>
                {intl.formatMessage({
                  id: "MENU.USER-MANAGEMENT.USER-LOG",
                })}
              </PageTitle>
              <ListUserLogWrapper />
            </>
          }
        />

        <Route
          path="users/active/detail/:id/*"
          element={
            <>
              <ProfilePage />
            </>
          }
        >
          {/* <Route path="" element={<Navigate to="post" />} /> */}
          <Route path="share" element={<ListShareUserProfile />} />
          <Route path="post" element={<ListPostUserProfile />} />
        </Route>

        <Route index element={<Navigate to="/user-management/users" />} />
      </Route>
    </Routes>
  );
};

export default UserModule;
