import { useLang } from "_metronic/i18n/Metronici18n";
import { DatePicker } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import { rangePresets, rangePresetsEn } from "app/utils";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface DateRangeCustomProps {
  // key: number;
  title: string;
  state: any;
  updateState: (value: any) => void;
}

export default function DateRangeCustom({
  // key,
  title,
  state,
  updateState,
}: DateRangeCustomProps) {
  const lang = useLang();
  return (
    <div
      // key={key}
      className="card-toolbar align-items-center d-flex min-w-100 min-w-sm-250px"
    >
      <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
      <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
        {title}:
      </span>

      {/* begin::Search */}
      <RangePicker
        onChange={(data, dateString) => {
          updateState({
            start_date: dateString[0],
            end_date: dateString[1],
            page: 1,
          });
        }}
        value={[
          state.start_date ? dayjs(state.start_date) : null,
          state.end_date ? dayjs(state.end_date) : null,
        ]}
        disabledDate={(date) => date && date.valueOf() > Date.now()}
        presets={lang === "ko" ? rangePresets : rangePresetsEn}
        locale={lang === "ko" ? ko : en}
      />
    </div>
  );
}
