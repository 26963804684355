/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "_metronic/assets/ts/_utils";
import { useThemeMode } from "_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { UserFlatForm } from "./core/_models";
import { useIntl } from "react-intl";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  data: UserFlatForm[] | undefined;
};

const colors = [
  "rgb(0, 143, 251)",
  "rgb(0, 227, 150)",
  "rgb(254, 176, 25)",
  "rgb(255, 69, 96)",
  "rgb(119, 93, 208)",
  "rgb(0, 143, 251)",
];

const PlatformWidget: React.FC<Props> = ({ className, data }) => {
  const intl = useIntl();

  const FLATFORM = [
    { title: "google", label: intl.formatMessage({ id: "PLATFORM-GOOGLE" }) },
    { title: "kakao", label: intl.formatMessage({ id: "PLATFORM-KAKAO" }) },

    {
      title: "wishnote",
      label: intl.formatMessage({ id: "PLATFORM-WISHNOTE" }),
    },
    { title: "amazon", label: intl.formatMessage({ id: "PLATFORM-AMAZON" }) },
    { title: "apple", label: intl.formatMessage({ id: "PLATFORM-APPLE" }) },
    { title: "naver", label: intl.formatMessage({ id: "PLATFORM-NAVER" }) },
    {
      title: "facebook",
      label: intl.formatMessage({ id: "PLATFORM-FACEBOOK" }),
    },
  ];

  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const labels =
    data &&
    data.map((item) => FLATFORM.find((x) => x.title == item.platform)?.label);
  const dataLabels = data && data.map((item) => parseInt(item.total));

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labels as any, dataLabels)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    let chart: any = null;
    if (data) {
      chart = refreshMode();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#fbfffb" }}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({ id: "PLATFORM" })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({ id: "CHART.PLATFORM.DESCRIPTION" })}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
        //className="row"
      >
        <div className="card-body">
          {/* begin::Chart */}
          <div
            ref={chartRef}
            id="kt_charts_widget_3_chart"
            //style={{ height: "350px" }}
          ></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}

        <div
          style={{
            paddingLeft: "2.25rem",
            marginRight: 50,
            display: "flex",
            gap: 30,
          }}
          //className="col-4 px-10 px-md-10"
        >
          <div className="d-flex flex-wrap">
            {data &&
              data.map((item, index) => (
                <div
                  className={`fw-bold col-6`}
                  style={{ color: colors[index], display: "flex" }}
                  key={index}
                >
                  <div
                    className="text-muted fw-semibold fs-7 "
                    style={{ width: 80 }}
                  >
                    {FLATFORM.find((x) => x.title == item.platform)?.label}:
                  </div>
                  <div style={{ marginRight: 8 }}>
                    <NumberDelay number={item.total} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlatformWidget };

function getChartOptions(
  height: number,
  labels: string[] | undefined,
  dataLabel: number[] | undefined
): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const baseColor = getCSSVariableValue("--bs-info");
  const lightColor = getCSSVariableValue("--bs-info-light");

  return {
    series: dataLabel,

    chart: {
      // width: "100%",
      height: 200,
      type: "pie",
    },
    labels: labels,

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    // theme: {
    //   mode: "dark",
    //   monochrome: {
    //     enabled: true,
    //   },
    // },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },

    legend: {
      show: false,
    },
  };
}
