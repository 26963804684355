import { FC, useState, createContext, useContext, useEffect } from "react";
import {
  QueryState,
  initialQueryRequest,
  WithChildren,
} from "_metronic/helpers";

const QueryRequestContext = createContext<any>(initialQueryRequest);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);
  const [pageInstance, setPageInstance] = useState(Math.random());
  const [triggerSearchClickState, setTriggerSearchClickState] = useState(
    Math.random()
  );

  const updateState = (updates: any) => {
    const updatedState = {
      ...state,
      ...updates,
    } as any;

    if (!updates?.max_page) delete updatedState.max_page;
    setState(updatedState);
  };

  const resetState = (updates: Partial<QueryState>) => {
    setState((updates as QueryState) || initialQueryRequest.state);
  };

  const searchClickAction = () => {
    setTriggerSearchClickState(Math.random());
  };

  useEffect(() => {
    searchClickAction();
  }, []);

  return (
    <QueryRequestContext.Provider
      value={{
        state,
        updateState,
        resetState,
        pageInstance,
        setPageInstance,
        triggerSearchClickState,
        searchClickAction,
      }}
    >
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };
