import { Table } from "app/_components/table/Table";
import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import { usersColumns } from "./table/_columns";
import { useMemo } from "react";
import { MenuComponent } from "_metronic/assets/ts/components";
import TableWrapper from "app/_components/coreListData";
import { getPaginationAllRankings } from "./core/_requests";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";

const UsersList = () => {
  const users = useQueryResponseData();
  const data = useMemo(() => users, [users]);
  MenuComponent.reinitialization();
  //const { pageInstance } = useQueryRequest();

  return (
    <div /* key={pageInstance} */>
      <KTCard>
        <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.RANKING_ALL} />
        <Table
          defineColumns={usersColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const UsersListWrapper = () => (
  <TableWrapper getPanigation={getPaginationAllRankings}>
    <UsersList />
  </TableWrapper>
);

export default UsersListWrapper;
