import axios, { AxiosResponse } from "axios";
import { ID, Response } from "_metronic/helpers";
import { User, UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;
const GET_USERS_URL = `${API_URL}/ranking`;
const GET_COUNTRIES_URL = `${API_URL}/master-data/country`;

const getUsers = (query: any, state: any): Promise<UsersQueryResponse> => {
  return (
    axios
      .get(`${GET_USERS_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.pagination;

        return {
          data: list,
          pagination: {
            page: state?.page,
            items_per_page: state?.items_per_page,
            links: linkPagination(
              state?.page,
              pagination?.total,
              state?.items_per_page
            ),
            total: pagination?.total,
          },
        };
      })
  );
};

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data);
};

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data);
};

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data);
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {});
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

const getCountries = () => {
  return axios
    .get(`${GET_COUNTRIES_URL}`, {
      params: {
        export: true,
      },
    })
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

export const getPaginationRankings = async (query: any) => {
  const defaultDate = dayjs().add(-1, "w");
  // if (!query?.start_date && !query?.end_date) {
  //   query.start_date = defaultDate.startOf("week").format("YYYY-MM-DD");
  //   query.end_date = defaultDate.endOf("week").format("YYYY-MM-DD");
  // }
  const newquery = applyDefaultQueryConditions({ ...query });
  if (!newquery?.start_date && !newquery?.end_date) {
    newquery.start_date = defaultDate.startOf("week").format("YYYY-MM-DD");
    newquery.end_date = defaultDate.endOf("week").format("YYYY-MM-DD");
  }

  if (!newquery.order_by) {
    newquery.sort_by = "total_point";
    newquery.order_by = "DESC";
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getCountries,
};
