import { useParams } from "react-router-dom";
import TableWrapper from "app/_components/coreListData";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { KTCard, PERMISSIONS_MAP, hasPermission } from "_metronic/helpers";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { getPaginationPurchaseByUser } from "../core/_requests";
import { usersColumns as PurchaseColumn } from "../../purchase-history/pages/list-purchase-history/table/_columns";
import { useAuth } from "app/_modules/auth";

const PurchaseProfile = () => {
  const { allPermission, myPermission } = useAuth();

  const users = useQueryResponseData();

  const data = useMemo(() => users, [users]);

  const hasPermissionUpdate = hasPermission(
    PERMISSIONS_MAP.UPDATE_WITHDRAW,
    myPermission,
    allPermission
  );

  let permissionColumns = useMemo(
    () => PurchaseColumn(hasPermissionUpdate),
    [allPermission, myPermission]
  );

  return (
    <div>
      <KTCard>
        <Table
          defineColumns={
            hasPermissionUpdate
              ? permissionColumns
              : permissionColumns.filter((i: any) => i.id !== "action")
          }
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const PurchaseProfileWrapper = () => {
  const params = useParams();
  return (
    <TableWrapper
      params={params.id}
      getPanigation={(query) => getPaginationPurchaseByUser(query, params.id)}
    >
      <PurchaseProfile />
    </TableWrapper>
  );
};

export default PurchaseProfileWrapper;
