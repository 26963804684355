import React, { FC } from "react";
import { FeedsWidget3 } from "./FeedsWidget3";
import InfiniteScroll from "react-infinite-scroll-component";
import { KTIcon } from "_metronic/helpers";
import { Box, LinearProgress } from "@mui/material";
import LoadingListFeeds from "./LoadingListFeeds";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import FilterByDate from "./FilterByDate";
import { useIntl } from "react-intl";
import { Select } from "antd";
import { CS_REPLY, NOTIFICATION_DEVICE } from "app/constants";

interface FeedsProps {
  data?: any;
  triggerListCS: () => void;
  loadMore: () => void;
  total: any;
  handleOnChangeKeyword: (keyword: string) => void;
  isFetching: boolean;
  page: number;
  updateDateParam: (value: string, value1: string) => void;
  updateData: (fieldsToUpdate: any) => void;
}
const Feeds: FC<FeedsProps> = ({
  data,
  triggerListCS,
  loadMore,
  total,
  handleOnChangeKeyword,
  isFetching,
  page,
  updateDateParam,
  updateData,
}) => {
  const handleLoadMore = () => {
    loadMore();
  };
  const intl = useIntl();
  return (
    <div className="row g-2 g-xl-2">
      <FilterHeader>
        <div className="w-100 px-0 rounded d-flex align-items-center flex-wrap me-5 filter-header gap-4">
          <form
            className="card-toolbar position-relative min-w-100 min-w-sm-250px"
            autoComplete="off"
          >
            <KTIcon
              iconName="magnifier"
              className="fs-2  text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y"
            />

            <input
              type="text"
              className="form-control bg-white form-control-solid px-15 w-100"
              name="search"
              placeholder="Search by review text"
              onChange={(e) => {
                handleOnChangeKeyword(e.target.value);
              }}
            />
          </form>

          <FilterByDate updateDateParam={updateDateParam} />

          <div className="card-toolbar align-items-center d-flex">
            <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">
              {intl.formatMessage({ id: "REPLY" })}:{" "}
            </span>
            <div className="d-flex align-items-center position-relative">
              <Select
                onChange={(value: any) => {
                  updateData({
                    is_reply: value.toString(),
                    page: 1,
                  });
                }}
                defaultValue={CS_REPLY[0].value}
                options={CS_REPLY.map((i: any) => {
                  return {
                    ...i,
                    label: intl.formatMessage({ id: i.label }),
                  };
                })}
                style={{ height: 40, width: 100 }}
              />
            </div>
          </div>
        </div>
      </FilterHeader>

      <Box sx={{ width: "100%" }}>
        {isFetching ? (
          <LinearProgress sx={{ borderRadius: 5, height: 2 }} />
        ) : (
          <div style={{ height: 4 }}></div>
        )}
      </Box>
      {isFetching && page <= 1 ? <LoadingListFeeds /> : null}

      {/* begin::Col */}
      <InfiniteScroll
        dataLength={data?.length || 0}
        next={handleLoadMore}
        scrollableTarget="scrollableDiv"
        hasMore={data && data.length < total ? true : false}
        loader={""} // loader={<LoadingListNotify />}
      >
        <div className="row g-3 g-xl-6 ms-0 me-0">
          {!isFetching &&
            data?.map((item: any, index: number) => (
              <div key={index} className="col-xl-4">
                <FeedsWidget3
                  triggerListCS={triggerListCS}
                  item={item}
                  className="mb-5 mb-xl-2"
                />
              </div>
            ))}
        </div>
      </InfiniteScroll>

      {/* end::Col */}
    </div>
  );
};

export { Feeds };
