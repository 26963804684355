import axios, { AxiosResponse } from "axios";
import { SummaryResponse, UserRankingResponse } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_SUMMARY_URL = `${API_URL}/users/summary`;
const GET_USERS_RANKING_URL = `${API_URL}/users/top-ranking`;

const getUsersSummary = (): Promise<SummaryResponse> => {
  return (
    axios
      .get(`${GET_USERS_SUMMARY_URL}`)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data.data;
      })
  );
};

const getUsersRanking = (type: string): Promise<UserRankingResponse> => {
  const params = {
    page: 1,
    type: type || "day",
  };
  return (
    axios
      .get(`${GET_USERS_RANKING_URL}`, {
        params: params,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data.data;
      })
  );
};

export { getUsersSummary, getUsersRanking };
