import { PageTitle } from "../../../_metronic/layout/core";

import { QUERIES, useDebounce } from "_metronic/helpers";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Feeds } from "./components/Feeds";
import { getListCS } from "./core/_requests";

const CSPage = () => {
  const [data, setData] = useState<any>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [paramsGetListCS, setParamsGetListCS] = useState({
    page: 1,
    limit: 12,
    start_date: new Date(Date.now() - 30 * 86400000)
      .toISOString()
      .substring(0, 10),
    end_date: new Date(Date.now()).toISOString().substring(0, 10),
    keyword: "",
  });
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.LIST_CS}`],
    () => {
      return getListCS(paramsGetListCS);
    },
    { cacheTime: 20000, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const loadMore = () => {
    setParamsGetListCS((prev) => {
      return {
        ...prev,
        page: prev.page + 1,
      };
    });
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      setParamsGetListCS((prev) => {
        return {
          ...prev,
          keyword: searchTerm,
          page: 1,
        };
      });
    }
  }, [debouncedSearchTerm]);

  const handleRefetch = () => {
    setParamsGetListCS((prev) => {
      return {
        ...prev,
        page: 1,
      };
    });
  };

  const handleOnChangeKeyword = (keyword: string) => {
    setSearchTerm(keyword);
  };

  const updateDateParam = (start_date: string, end_date: string) => {
    setParamsGetListCS((prev: any) => {
      return {
        ...prev,
        start_date: start_date,
        end_date: end_date,
        page: 1,
      };
    });
  };
  const updateData = (fieldsToUpdate: any) => {
    const updatedData = { ...paramsGetListCS, ...fieldsToUpdate };
    setParamsGetListCS(updatedData);
  };

  useEffect(() => {
    refetch();
  }, [paramsGetListCS]);

  useEffect(() => {
    if (response && paramsGetListCS.page !== 1) {
      setData((prev: any) => {
        return [...prev, ...response?.list];
      });
    } else {
      setData(response?.list);
    }
  }, [response]);

  return (
    <>
      <PageTitle>CS</PageTitle>

      <Feeds
        data={data}
        total={response?.pagination?.total}
        triggerListCS={handleRefetch}
        loadMore={loadMore}
        handleOnChangeKeyword={handleOnChangeKeyword}
        isFetching={isFetching}
        page={paramsGetListCS.page}
        updateDateParam={updateDateParam}
        updateData={updateData}
      />

      {!isFetching && (!data || data.length <= 0) ? (
        <EmptyChart height={500} />
      ) : null}
    </>
  );
};

export default CSPage;
