import { useEffect, useMemo, useState } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";

import { usersColumns } from "./columns/_columns";
import { User } from "../core/_models";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { UsersListPagination } from "../components/pagination/UsersListPagination";
import { KTCardBody } from "_metronic/helpers";
import {
  getArticlesByUser,
  getJourneyByUser,
  getShareByUser,
} from "../core/_requests";
import { _listPostsOfUserColumn } from "./columns/_listPostsOfUserColumn";
import { _listSharesOfUserColumn } from "./columns/_listSharesOfUserColumn";
import { useQueryResponseLoading } from "app/_components/coreListData/core/QueryResponseProvider";
const UsersTable = ({ params, type = "" }: any) => {
  const [userJourney, setUserJourney] = useState<any>();

  // const users = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  // const data = useMemo(() => users, [users]);

  const columns = useMemo(() => usersColumns, []);

  const listPostsOfUserColumn: any = useMemo(() => _listPostsOfUserColumn, []);

  const columnsRender: any = useMemo(
    () =>
      type === "list-post"
        ? listPostsOfUserColumn
        : type === "list-share"
        ? _listSharesOfUserColumn
        : columns,
    [type]
  );

  const getListPostData = async () => {
    const res = await getArticlesByUser(params?.id, { limit: 50, page: 1 });
    setUserJourney(res.data.data.list);
  };
  const getListShareData = async () => {
    const res = await getShareByUser(params?.id, { limit: 50, page: 1 });
    setUserJourney(res.data.data.list);
  };

  const data = userJourney || [];

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns: columnsRender,
      data,
    });

  useEffect(() => {
    if (type === "list-post") {
      getListPostData();
      return;
    }
    if (type === "list-share") {
      getListShareData();
      return;
    }
    async function GetData() {
      const journey = await getJourneyByUser(params?.id);
      setUserJourney(journey);
    }

    GetData();
  }, []);

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows?.length > 0 ? (
              rows?.map((row: Row<User>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};

export { UsersTable };
