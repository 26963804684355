import { Box, Tooltip } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { useIntl } from "react-intl";

export default function ToolTipRanking() {
  const intl = useIntl();
  return (
    <Tooltip
      // slotProps={{
      //   tooltip: { sx: { backgroundColor: "#A69178" } },
      //   arrow: { sx: { color: "#A69178" } },
      // }}
      title={
        <Box
          sx={{
            p: {
              fontSize: "14px",
              fontWeight: 500,
              pb: 0,
            },
            ul: {
              px: 1,
              pl: 2,
              mb: 0,
            },
            li: {
              py: 0.5,
            },
          }}
        >
          <ul>
            <li>{intl.formatMessage({ id: "TOOLTIP.INFO1" })}</li>
            <li> {intl.formatMessage({ id: "TOOLTIP.INFO2" })}</li>
            <li> {intl.formatMessage({ id: "TOOLTIP.INFO3" })}</li>
          </ul>
        </Box>
      }
      placement="right-start"
      sx={{
        "&.MuiTooltip-popper": {
          backgroundColor: "red",
        },
      }}
      arrow
    >
      <div className="cursor-pointer mt-n1 ms-1">
        <KTIcon iconName="information-2" className="fs-4" />
      </div>
    </Tooltip>
  );
}
