import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { UsersListToolbar } from "../components/UserListToolbar";
import { getPaginationOnGoingEvent } from "./core/_requests";
import { usersColumns } from "./table/_columns";
import { useAuth } from "app/_modules/auth";
import TableSummary from "app/_components/coreListData/header/TableSummary";
import { useIntl } from "react-intl";

const OnGoingEvents = (isTriggerListEvent: any) => {
  const users = useQueryResponseData();
  const res = useQueryResponsePagination();
  const intl = useIntl();
  const { refetch } = useQueryResponse();
  const data = useMemo(() => users, [users]);
  const triggerListEvent = () => {
    refetch();
  };

  const { allPermission, myPermission } = useAuth();

  const hasPermissionCreateEvent = hasPermission(
    PERMISSIONS_MAP.CREATE_EVENT,
    myPermission,
    allPermission
  );

  const cardList = [
    {
      title: intl.formatMessage({
        id: "Event",
      }),
      value: res?.total,
    },
  ];

  return (
    <>
      <div /* key={pageInstance} */>
        <KTCard>
          <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.EVENTS} />

          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <TableSummary cardList={cardList} />
            <div className="card-toolbar p-8">
              {/* begin::Group actions */}
              {hasPermissionCreateEvent && (
                <UsersListToolbar
                  triggerListEvent={triggerListEvent}
                  className="mb-2"
                />
              )}
              {/* end::Group actions */}
            </div>
          </div>

          <Table
            defineColumns={usersColumns}
            data={data}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
          />
        </KTCard>
      </div>
    </>
  );
};

const OnGoingEventsWrapper = (isTriggerListEvent: any) => (
  <TableWrapper getPanigation={getPaginationOnGoingEvent}>
    <OnGoingEvents isTriggerListEvent={isTriggerListEvent} />
  </TableWrapper>
);

export default OnGoingEventsWrapper;
