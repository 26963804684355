// @ts-nocheck
import { Tag } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";

const EXTRA_TYPE = [
  {
    value: "charge",
    label: "Charge",
    color: "red",
  },
  {
    value: "revenue",
    label: "Revenue",
    color: "yellow",
  },
  {
    value: "gift",
    label: "Gift",
    color: "green",
  },
  {
    value: "bonus",
    label: "Bonus",
    color: "black",
  },
  {
    value: "refund",
    label: "Refund",
    color: "info",
  },
  {
    value: "withdrawal",
    label: "Withdrawal",
    color: "blue",
  },
  {
    value: "buy",
    label: "Buy",
    color: "pink",
  },
  {
    value: "donate",
    label: "Donate",
    color: "orange",
  },
];

const usersColumns: any = (hasPermissionUpdate: boolean) => [
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="USER"
  //       className="min-w-150px"
  //       useQueryRequest={useQueryRequest}
  //     />
  //   ),
  //   id: "user",
  //   Cell: ({ ...props }) => {
  //     const row = props?.row?.original;

  //     return <UserInfoCell user={row?.user_profile} />;
  //   },
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="BALANCE-BEFORE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "balance_before",
    accessor: "balance_before",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <>{new Intl.NumberFormat().format(row?.balance_before) || "0"}</>;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="AMOUNT"
        className="min-w-125px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    isCenterRow: true,
    id: "amount",
    accessor: "amount",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <>{new Intl.NumberFormat().format(row?.amount) || "0"}</>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="BALANCE-AFTER"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "balance_after",
    accessor: "balance_after",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <>{new Intl.NumberFormat().format(row?.balance_after) || "0"}</>;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="EXTRA-TYPE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "extra_type",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      return (
        <div className="d-flex flex-wrap">
          <Tag
            style={{ marginTop: 10 }}
            bordered={false}
            color={EXTRA_TYPE.find((i) => i.value === row.extra_type)?.color}
          >
            {EXTRA_TYPE.find((i) => i.value === row.extra_type)?.label}
          </Tag>
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-125px"
        isNoSort={false}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <DateTimeCell date={row.created_at} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        isNoSort={false}
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <DateTimeCell date={row.updated_at} />;
    },
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="ACTION"
  //       useQueryRequest={useQueryRequest}
  //       isCenter={true}
  //     />
  //   ),
  //   id: "action",
  //   isCenterRow: true,
  //   Cell: ({ ...props }) => (
  //     <div className="d-flex text-end" style={{ gap: 5 }}>
  //       {hasPermissionUpdate && (
  //         <EditWithdrawModal row={props?.row?.original} />
  //       )}
  //     </div>
  //   ),
  // },
];

// function EditWithdrawModal({ row }) {
//   const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

//   const handleOnClose = () => {
//     setShowCreateAppModal(false);
//   };

//   return (
//     <>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <button
//           type="button"
//           className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
//           onClick={() => setShowCreateAppModal(true)}
//         >
//           <KTIcon iconName="notepad-edit" className="fs-2" />
//         </button>
//       </div>

//       {showCreateAppModal && (
//         <CreateAppModal
//           show={showCreateAppModal}
//           handleClose={handleOnClose}
//           info={row}
//         />
//       )}
//     </>
//   );
// }

// function DeleteComponent({ row }) {
//   const { refetch } = useQueryResponse();
//   const [isLoadingDelete, setIsLoadingDelete] = useState(false);
//   const intl = useIntl();

//   const handleDelete = async () => {
//     setIsLoadingDelete(true);
//     const res = await delete_product(row.id);
//     setIsLoadingDelete(false);
//     if (res.status == 200) {
//       notification.success({
//         message: intl.formatMessage({ id: "DELETE.PRODUCT-SUCCESS" }),
//       });
//       refetch();
//     }
//   };

//   return (
//     <div style={{ display: "flex", justifyContent: "center" }}>
//       <Popconfirm
//         title="Delete"
//         description="Are you sure to delete this product?"
//         onConfirm={handleDelete}
//         onCancel={() => {}}
//         okText="Yes"
//         cancelText="No"
//       >
//         <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
//           {isLoadingDelete ? (
//             <CircularProgress size={16} />
//           ) : (
//             <KTIcon
//               iconName="trash"
//               className="fs-2 color-primary fill-primary"
//             />
//           )}
//         </button>
//       </Popconfirm>
//     </div>
//   );
// }

export { usersColumns };
