import axios, { AxiosResponse } from "axios";
import { ID, Response } from "_metronic/helpers";
import { User, UsersQueryResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/users`;
const GET_USERS_URL = `${API_URL}/users/query`;

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};

const getUserById = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/users/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const getCouponByUser = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/admin/users/${id}/coupons?page=1&limit=10`)
    .then((response: any) => response.data)
    .then((response: any) => response.data.list);
};

const getJourneyByUser = (id: any): Promise<any> => {
  return axios
    .get(`${API_URL}/admin/users/${id}/journey?page=1&limit=10`)
    .then((response: any) => response.data)
    .then((response: any) => response.data.list);
};

const getArticlesByUser = (id: any, body: any) => {
  return axios.get(`${API_URL}/users/${id}/articles`, { params: body });
};
const getShareByUser = (id: any, body: any) => {
  return axios.get(`${API_URL}/users/${id}/share`, { params: body });
};

const give_coupon_to_user = (id: any, body: any) => {
  return axios.post(`${API_URL}/admin/users/${id}/coupons/create`, body);
};

export const getPaginationArticlesByUser = async (query: any, param: any) => {
  const id = param;
  const newquery = applyDefaultQueryConditions(query);

  if (id) {
    newquery.user_id = id;
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${API_URL}/posts`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
  };
};

const getPaginationPurchaseByUser = async (query: any, params: any) => {
  const newquery = applyDefaultQueryConditions(query);

  if (params) {
    newquery.user_id = params;
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${USER_URL}/purchase-history`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
    user_platform: user_platform,
  };
};

const getPaginationPosts = async (query: any, params: any) => {
  const newquery = applyDefaultQueryConditions(query);

  if (params) {
    newquery.user_id = params;
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${API_URL}/articles`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
    user_platform: user_platform,
  };
};

export const getPaginationLogsByUser = async (query: any, param: any) => {
  const id = param;
  const newquery = applyDefaultQueryConditions(query);

  const res = await callApiWithTryCatch(
    async () =>
      await axios.get(`${USER_URL}/logs`, {
        params: { ...newquery, user_id: id },
      })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
  };
};

const getPaginationConnectSocial = async (query: any, id: any) => {
  const newquery = applyDefaultQueryConditions(query);

  // if (params) {
  //   newquery.user_id = params;
  // }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${USER_URL}/${id}/connect`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
    },
    user_platform: user_platform,
  };
};

const updateStatusConnect = (body: any) => {
  return axios.put(`${USER_URL}/update-status-connect`, body);
};

const updateBusinessStatus = (userID: string, status: number) => {
  return axios.put(`${USER_URL}/${userID}/business/${status}`);
};

export {
  getUsers,
  getUserById,
  getArticlesByUser,
  getCouponByUser,
  getJourneyByUser,
  give_coupon_to_user,
  getShareByUser,
  getPaginationPurchaseByUser,
  getPaginationPosts,
  getPaginationConnectSocial,
  updateStatusConnect,
  updateBusinessStatus,
};
