import { SvgIcon } from "@mui/material";
import { toAbsoluteUrl } from "_metronic/helpers";

interface ChipPointProps {
  point: number;
}

export default function ChipPoint({ point }: ChipPointProps) {
  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        borderRadius: 18,
        padding: "6px 8px",
        color: "#F1416C",
        fontSize: 12,
        lineHeight: 1.2,
        textTransform: "uppercase",
        width: "100%",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <img
        src={toAbsoluteUrl("/media/svg/points/point-wp.svg")}
        alt=""
        className="h-20px"
      />
      <span style={{ marginLeft: 4 }}>{point + " "}P</span>
    </div>
  );
}
