/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from "react-intl";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import TabsProfileInfo from "./components/TabsProfileInfo";
import { Tooltip, Typography } from "@mui/material";
import clsx from "clsx";

const ProfileHeader = ({ user, handleChangeTab }: any) => {
  const intl = useIntl();
  const sum = (values: object) => {
    if (values) {
      const temp = Object.values(values).reduce(
        (partialSum: number, a: any) => partialSum + a,
        0
      );
      return temp || 0;
    }
    return 0;
  };
  return (
    <div className="card">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex  flex-row flex-lg-column flex-xl-row col-12 flex-wrap mb-3 ">
          <div className="d-flex align-items-start flex-wrap mb-2 flex-column flex-sm-row ">
            <div className="me-7 mb-4">
              <div className="symbol symbol-circle symbol-100px overflow-hidden ">
                <div className={clsx("symbol-label fs-3")}>
                  <img
                    alt="user"
                    src={toAbsoluteUrl(
                      user?.profile_image_url
                        ? user?.profile_image_url
                        : "/media/avatars/blank.png"
                    )}
                    className="h-100 w-100 object-fit-cover"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column fw-semibold">
              <div className="d mb-6">
                <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                  {user?.nickname ? `${user?.nickname} ` : "No name"}
                </div>
                <div className="d-flex align-items-center text-gray-600 mb-2">
                  <KTIcon iconName="sms" className="fs-4 me-1" />
                  {user?.email || "-"}
                </div>
                <span
                  className="d-flex align-items-center fs-7 text-gray-400"
                  style={{ marginRight: 8 }}
                >
                  <KTIcon iconName="calendar" className="fs-4 me-1" />
                  {intl.formatMessage({ id: "CREATED-AT" })}:{" "}
                  {user?.created_at || "-"}
                </span>
              </div>

              <div className="d-flex flex-column flex-wrap fw-bold fs-6 mb-4 pe-2 gap-5">
                {/* <div className="d-flex gap-15 flex-column flex-lg-row">
                  <div>
                    <a
                      href="#"
                      className="fs-7 d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <span className="fs-7 " style={{ marginRight: 8 }}>
                        {intl.formatMessage({ id: "BIRTH-DAY" })}:
                      </span>
                      {user?.birthday?.split(" ")[0] || "_"}
                    </a>
                    <a
                      href="#"
                      className="fs-7 d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      {" "}
                      <span className="fs-7 " style={{ marginRight: 8 }}>
                        {intl.formatMessage({ id: "SEX" })}:
                      </span>
                      {user?.sex && user?.sex === 0
                        ? intl.formatMessage({ id: "FEMALE" })
                        : intl.formatMessage({ id: "MALE" })}
                    </a>
                  </div>
                  <div>
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <span className="fs-7 " style={{ marginRight: 8 }}>
                        {intl.formatMessage({ id: "CREATED-AT" })}:
                      </span>
                      {user?.created_at || "-"}
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <span className="fs-7 " style={{ marginRight: 8 }}>
                        {intl.formatMessage({ id: "UPDATED-AT" })}:
                      </span>
                      {user?.updated_at || "-"}
                    </a>
                  </div>
                </div> */}

                <div
                  className="d-flex align-items-center me-5 flex-wrap"
                  style={{ gap: 5 }}
                >
                  <div className="d-flex align-items-center flex-shrink-0 me-5">
                    <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1">
                      {intl.formatMessage({ id: "CONNECTIONS" })}
                    </span>
                    {/* end::Label */}
                    {new Intl.NumberFormat().format(
                      user?.connecting + user?.connection
                    ) || "0"}
                  </div>

                  <div className="d-flex align-items-center flex-shrink-0 me-5">
                    {/* begin::Desktop separartor */}
                    <div className="bullet bg-secondary h-20px w-1px me-5"></div>
                    {/* end::Desktop separartor */}
                    {/* begin::Label */}
                    <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1">
                      {intl.formatMessage({ id: "CONTENTS" })}
                    </span>
                    {/* end::Label */}
                    {user?.post_count_undeleted
                      ? new Intl.NumberFormat().format(
                          user?.post_count_undeleted
                        )
                      : 0}
                  </div>

                  <div className="d-flex align-items-center flex-shrink-0 me-5">
                    {/* begin::Desktop separartor */}
                    <div className="bullet bg-secondary h-20px w-1px me-5"></div>
                    {/* end::Desktop separartor */}
                    {/* begin::Label */}
                    <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1">
                      {intl.formatMessage({ id: "MY-PIC" })}
                    </span>
                    {/* end::Label */}
                    {new Intl.NumberFormat().format(sum(user?.fund)) || "0"}
                  </div>
                </div>

                <div className="fw-bold fs-6 mb-4 w-100">
                  <div className="d-flex flex-column flex-sm-row">
                    {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-flex justify-content-between flex-column">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {user?.rank || "0"}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {intl.formatMessage({ id: "RANK" })}
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-flex justify-content-between flex-column">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {user?.score || "0"}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {intl.formatMessage({ id: "SCORE" })}
                      </div>
                    </div> */}
                    {/*<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-flex justify-content-between flex-column">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {" "}
                          {user?.follow_count || "0"}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {intl.formatMessage({ id: "FOLLOW-COUNT" })}
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-flex justify-content-between flex-column">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bolder">
                          {" "}
                          {user?.acticle_count || "0"}
                        </div>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {intl.formatMessage({ id: "ARTICLE-COUNT" })}
                      </div>
                    </div>
                     <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 d-flex justify-content-between flex-column">
                      <div className="d-flex align-items-center gap-8">
                        <Tooltip
                          title={intl.formatMessage({ id: "LIKE-COUNT" })}
                          placement="top-start"
                        >
                          <div className="fs-2 d-flex flex-column fw-bolder cursor-pointer">
                            {user?.like_count || "0"}
                            <KTIcon iconName="like" className="fs-4 me-1" />
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({ id: "COMMENT-COUNT" })}
                          placement="top-start"
                        >
                          <div className="fs-2 d-flex flex-column fw-bolder cursor-pointer">
                            {user?.comment_count || "0"}
                            <KTIcon
                              iconName="message-text"
                              className="fs-4 me-1"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({ id: "BOOKMARK-COUNT" })}
                          placement="top-start"
                        >
                          <div className="fs-2 d-flex flex-column fw-bolder cursor-pointer">
                            {user?.bookmark_count || "0"}
                            <KTIcon iconName="bookmark" className="fs-4 me-1" />
                          </div>
                        </Tooltip>
                      </div>

                      <div className="fw-bold fs-6 text-gray-400">
                        {intl.formatMessage({ id: "REACTION-COUNT" })}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TabsProfileInfo handleChangeTab={handleChangeTab} user={user} />
    </div>
  );
};

export { ProfileHeader };
