// @ts-nocheck
import { Column } from "react-table";
import { User } from "../../core/_models";
import Fancybox from "app/_components/Fancybox";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { toAbsoluteUrl } from "_metronic/helpers";

const _listSharesOfUserColumn: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",

    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row.user} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="IMAGE"
        className="min-w-100px"
        isNoSort={true}
        useQueryRequest={useQueryRequest}
        isCenter
      />
    ),
    id: "images",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;

      let imagesURL = [];
      switch (row.article_type) {
        case "wish":
          imagesURL = [row.board_thumbnail];
          break;
        case "goal":
          //puzzle image -> images includes thumbnail || images is empty
          //no => show all images, thumbnail meaninglesss

          //thumb + no image => thumb
          //thumb + images => images includes thumb => images (list images)
          //thumb + images => images not includes thumb => PUZZLE => thumb

          if (
            !row.images.includes(row.board_thumbnail) ||
            row.images.length == 0
          )
            imagesURL = [row.board_thumbnail];
          else imagesURL = row.images;

          if (row.is_completed) imagesURL = row.images;

          break;
      }

      return (
        <Fancybox
          options={{
            Carousel: {
              infinite: true,
            },
          }}
          key={Math.random()}
        >
          <div style={{ display: "inline-block", maxWidth: "100%" }}>
            <div className="post_img">
              {imagesURL?.map((url, index) => (
                <div className="symbol symbol-70px me-3 position-relative">
                  {index == 0 ? (
                    <>
                      <div className="symbol-label overflow-hidden">
                        <a
                          data-fancybox="gallery"
                          href={url}
                          key={Math.random()}
                        >
                          <img src={url} width="200" height="150" />
                        </a>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "none" }}>
                      <a data-fancybox="gallery" href={url} key={Math.random()}>
                        <img src={url} width="200" height="150" />
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Fancybox>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ARTICLE_TYPE"
        className="min-w-125px"
        isNoSort={false}
        isCenter
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "article_type",
    isCenterRow: true,

    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const intl = useIntl();

      return (
        <p className="text-center">
          {row?.article_type?.toLowerCase()?.includes("wish")
            ? intl.formatMessage({ id: "WISHBOARD" })
            : intl.formatMessage({ id: "GOAL" })}
        </p>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DETAILS"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "description",
    accessor: "description",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-100px"
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-100x"
        isNoSort={false}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    isCenterRow: true,
  },
];

const UserInfoCell = ({ user }) => (
  <div className="d-flex align-items-center">
    {/* begin:: Avatar */}
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
      {user?.avatar ? (
        <div className="symbol-label">
          <div
            className={`align-self-end my-auto h-100 w-100`}
            style={{
              backgroundImage: `url(${user?.avatar})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      ) : (
        <div className={clsx("symbol-label fs-3")}>
          <img
            src={toAbsoluteUrl("/media/avatars/blank.png")}
            className="w-100"
          />
        </div>
      )}
    </div>
    <div className="d-flex flex-column">
      <a href="#" className="text-gray-800 text-hover-primary mb-1">
        {user?.name}
      </a>
      <span>{user?.email}</span>
    </div>
  </div>
);

export { _listSharesOfUserColumn };
