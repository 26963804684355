import axios, { AxiosResponse } from "axios";
import { ListNotificationResponse } from "./_models";
import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_LIST_NOTIFICATION_URL = `${API_URL}/notifications/notice`;
const POST_NEW_NOTIFICATION_URL = `${API_URL}/notifications/notice/create`;
const GET_LIST_NOTIFICATION_TYPE_URL = `${API_URL}/notifications/type-list`;
const CREATE_NEW_NOTIFICATION_TYPE_URL = `${API_URL}/notifications/create-type`;
const DELETE_NOTICE_URL = `${API_URL}/notifications/notice`;

const getListNotification = (
  params: any
): Promise<ListNotificationResponse> => {
  return (
    axios
      .get(`${GET_LIST_NOTIFICATION_URL}`, { params: params })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data.data;
      })
  );
};

const createNewNotification = (
  params: any
): Promise<ListNotificationResponse> => {
  return (
    axios
      .post(`${POST_NEW_NOTIFICATION_URL}`, params)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data;
      })
  );
};

const getListTypeSettingNotification = (): Promise<any> => {
  return (
    axios
      .get(`${GET_LIST_NOTIFICATION_TYPE_URL}`)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data;
      })
  );
};

const addNewTypeNotification = (body: any): Promise<any> => {
  return (
    axios
      .post(`${CREATE_NEW_NOTIFICATION_TYPE_URL}`, body)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data;
      })
  );
};

const deleteNotice = (id: string): Promise<any> => {
  return (
    axios
      .delete(`${DELETE_NOTICE_URL}/${id}/delete`)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data;
      })
  );
};

export const getPaginationListNotice = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  if (!newquery.redirect_type) {
    newquery.redirect_type = 16;
  }
  const res = await callApiWithTryCatch(
    async () =>
      await axios.get(`${GET_LIST_NOTIFICATION_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

export {
  getListNotification,
  createNewNotification,
  getListTypeSettingNotification,
  addNewTypeNotification,
  deleteNotice,
};
