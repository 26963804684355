import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import TableWrapper from "app/_components/coreListData";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { KTCard, PERMISSIONS_MAP, hasPermission } from "_metronic/helpers";
import { Table } from "app/_components/table/Table";
import { useMemo, useState } from "react";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { getPaginationArticlesByUser } from "../core/_requests";
import { useAuth } from "app/_modules/auth";
import { _listPostsOfUserColumn } from "../table/columns/_listPostsOfUserColumn";

const ListPostUserProfile = () => {
  const intl = useIntl();
  const { allPermission, myPermission } = useAuth();
  const users = useQueryResponseData();
  const { state } = useQueryRequest();

  const [row, setRow] = useState<any>(null);
  const [isOpenModalLike, setIsOpenModalLike] = useState(false);
  const [type, setType] = useState("");
  const updateRow = (row: any, type: string) => {
    setType(type);

    setIsOpenModalLike(true);
    setRow(row);
  };
  const data = useMemo(() => users, [users]);

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.DELETE_POST,
    myPermission,
    allPermission
  );

  const hasPermissionRestore = hasPermission(
    PERMISSIONS_MAP.RESTORE_POST,
    myPermission,
    allPermission
  );

  let columns = _listPostsOfUserColumn(updateRow).filter((i: any) => {
    if (
      (!state.is_deleted && !hasPermissionDelete && i.id == "action-delete") ||
      (state.is_deleted && !hasPermissionRestore && i.id == "action-delete") ||
      (!hasPermissionDelete && !hasPermissionRestore && i.id == "action-delete")
    )
      return false;
    return true;
  });

  return (
    <div>
      <KTCard>
        <Table
          defineColumns={columns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const ListPostUserProfileWrapper = () => {
  const params = useParams();
  return (
    <TableWrapper
      params={params.id}
      getPanigation={getPaginationArticlesByUser}
    >
      <ListPostUserProfile />
    </TableWrapper>
  );
};

export default ListPostUserProfileWrapper;
