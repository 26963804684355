/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useIntl } from "react-intl";

const mappedLabel = (label: string): string => {
  if (label === "&laquo; Previous") {
    return "Previous";
  }

  if (label === "Next &raquo;") {
    return "Next";
  }

  return label;
};

const Pagination = ({
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryRequest,
}: any) => {
  const pagination = useQueryResponsePagination();

  const isLoading = useQueryResponseLoading();
  const { updateState } = useQueryRequest();

  const updatePage = (page: number | null) => {
    // eslint-disable-next-line eqeqeq
    if (!page || isLoading || pagination.page == page) {
      return;
    }
    updateState({
      page /* , items_per_page: pagination.items_per_page  */,
    });
  };
  const intl = useIntl();
  //intl.formatMessage({ id: "PREVIOUS" })
  return (
    <div className="row pt-5">
      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start text-gray-600 fw-bold">
        {/* Total: {pagination?.total} records */}
      </div>
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            {pagination.links
              ?.map((link: any) => {
                return { ...link, label: mappedLabel(link.label) };
              })
              .map((link: any) => (
                <li
                  key={link.label}
                  className={clsx("page-item", {
                    // eslint-disable-next-line eqeqeq
                    active: pagination.page == link.page,
                    disabled: isLoading,
                    // previous: link.label === "Previous",
                    // next: link.label === "Next",
                  })}
                >
                  <a
                    className={clsx("page-link", {
                      "page-text":
                        link.label === "Previous" || link.label === "Next",
                      "me-5": link.label === "Previous",
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{ cursor: "pointer" }}
                  >
                    {typeof link.label === "number"
                      ? mappedLabel(link.label)
                      : mappedLabel(intl.formatMessage({ id: link.label }))}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Pagination };
