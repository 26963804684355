import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  WithChildren,
} from "_metronic/helpers";
import { useQueryRequest } from "./QueryRequestProvider";
import { isEmptyObject } from "jquery";
import moment from "moment";

interface QueryResponseProviderProps extends WithChildren {
  module?: string;
  params: any;
  getPanigationData?: (query: any, custom: any) => Promise<any>;
}

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const QueryResponseProvider: FC<QueryResponseProviderProps> = ({
  children,
  module,
  params,
  getPanigationData,
}) => {
  const { state, triggerSearchClickState } = useQueryRequest();
  const [query, setQuery] = useState<any>();

  const updatedQuery = useMemo(() => {
    let customize_users_query = {
      ...(state.page && { page: state.page }),
      ...(state.items_per_page && { limit: state.items_per_page }),
      ...(state.search && { keyword: state.search }),
      ...(state.status && state.status !== "all" && { status: state.status }),
      ...(state.start_date && {
        start_date: moment(state.start_date)
          .startOf("D")
          .subtract(9, "h")
          .format("YYYY-MM-DD HH:mm:ss"),
      }),
      ...(state.end_date && {
        end_date: moment(state.end_date)
          .endOf("D")
          .subtract(9, "h")
          .format("YYYY-MM-DD HH:mm:ss"),
      }),
      ...(state.platform &&
        state.platform.length > 0 && {
          platform: state.platform.toString(),
        }),
      ...(state.sort && { sort_by: state.sort }),
      ...(state.order && { order_by: state.order.toUpperCase() }),
      ...(state.is_deleted && {
        is_deleted: state.is_deleted,
      }),
      ...(state.article_type && { article_type: state.article_type }),
      ...(state.device_type && { device_type: state.device_type }),
      ...(state.push_status && { push_status: state.push_status }),
      ...(state.type && { type: state.type }),
      ...(state.group && { group: state.group }),
      ...(state.device_os &&
        state.device_os !== "all" && { device_os: state.device_os }),
      ...(state.role_id && { role_id: state.role_id }),
      ...(state.type && { type: state.type }),
      ...(state.extra_type && { extra_type: state.extra_type }),
      ...(state.redirect_type && { redirect_type: state.redirect_type }),
      ...(state.request_status && { request_status: state.request_status }),
      ...(state.search_youtube && { youtube_url: state.search_youtube }),
      ...(state.search_instagram && { instagram_url: state.search_instagram }),
      ...(state.search_user && { user: state.search_user }),
      ...(state.min_view_count && { min_view_count: state.min_view_count }),
      ...(state.max_view_count && { max_view_count: state.max_view_count }),
      ...(state.user_id && { user_id: state.user_id }),
      ...(state.max_page && { max_page: state.max_page }),
      ...(state.is_pass && { is_pass: state.is_pass }),
      ...(state.keyword && { keyword: state.keyword }),
      ...(state.method && { method: state.method }),
      ...(state.phone_code && { phone_code: state.phone_code }),
      ...(state.event_status && { event_status: state.event_status }),
    };

    return customize_users_query;
  }, [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [state]);

  console.log(triggerSearchClickState);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [
      /* query,  */ module,
      triggerSearchClickState,
      query?.sort_by,
      query?.order_by,
      query?.page,
      query?.limit,
    ],
    () => {
      if (!query) return;
      console.log("query");
      return getPanigationData && getPanigationData(query, params);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response }: any = useQueryResponse();
  if (!response) {
    return [];
  }
  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response }: any = useQueryResponse();
  if (!response || !response?.pagination) {
    return defaultPaginationState;
  }
  return response.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
