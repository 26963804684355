export default function LoadingListFeeds() {
  return (
    <div className="row p-0 mt-2">
      <div className="col-xl-4">
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: "right top",
            backgroundSize: "30% auto",
          }}
        >
          {/* begin::Body */}
          <div className="card-body shadow py-8 placeholder-glow">
            <div className="d-flex justify-content-between align-items-center mb-6">
              <span className="placeholder bg-secondary w-50px h-50px rounded-circle"></span>
              <span className="placeholder bg-secondary col-3 h-25px rounded"></span>
              <span className="placeholder bg-secondary col-5 rounded"></span>
            </div>
            <a
              href="#"
              className="card-title  fw-bold text-muted text-hover-primary fs-4"
            >
              <span className="placeholder bg-secondary col-3 rounded"></span>
            </a>

            <p className="text-dark-75 fw-semibold fs-5 m-0 mt-4">
              <span className="placeholder bg-secondary col-10 h-25px rounded"></span>
            </p>
          </div>
          {/* end::Body */}
        </div>
      </div>
      <div className="col-xl-4 ">
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: "right top",
            backgroundSize: "30% auto",
          }}
        >
          {/* begin::Body */}
          <div className="card-body shadow py-8 placeholder-glow">
            <div className="d-flex justify-content-between align-items-center mb-6">
              <span className="placeholder bg-secondary w-50px h-50px rounded-circle"></span>
              <span className="placeholder bg-secondary col-3 h-25px rounded"></span>
              <span className="placeholder bg-secondary col-5 rounded"></span>
            </div>
            <a
              href="#"
              className="card-title  fw-bold text-muted text-hover-primary fs-4"
            >
              <span className="placeholder bg-secondary col-3 rounded"></span>
            </a>

            <p className="text-dark-75 fw-semibold fs-5 m-0 mt-4">
              <span className="placeholder bg-secondary col-10 h-25px rounded"></span>
            </p>
          </div>
          {/* end::Body */}
        </div>
      </div>
      <div className="col-xl-4 ">
        <div
          className={`card bgi-no-repeat card overflow-hidden`}
          style={{
            backgroundPosition: "right top",
            backgroundSize: "30% auto",
          }}
        >
          {/* begin::Body */}
          <div className="card-body shadow py-8 placeholder-glow">
            <div className="d-flex justify-content-between align-items-center mb-6">
              <span className="placeholder bg-secondary w-50px h-50px rounded-circle"></span>
              <span className="placeholder bg-secondary col-3 h-25px rounded"></span>
              <span className="placeholder bg-secondary col-5 rounded"></span>
            </div>
            <a
              href="#"
              className="card-title  fw-bold text-muted text-hover-primary fs-4"
            >
              <span className="placeholder bg-secondary col-3 rounded"></span>
            </a>

            <p className="text-dark-75 fw-semibold fs-5 m-0 mt-4">
              <span className="placeholder bg-secondary col-10 h-25px rounded"></span>
            </p>
          </div>
          {/* end::Body */}
        </div>
      </div>
    </div>
  );
}
