import { linkPagination } from "app/utils";
import axios, { AxiosResponse } from "axios";
import { NotificationLogQueryResponse } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_NOTIFICATION_LOG_URL = `${API_URL}/notifications/logs`;
const GET_NOTIFICATION_LOG_TYPE_URL = `${API_URL}/notifications/logs-type`;

const getNotificationLogs = (query: any): Promise<NotificationLogQueryResponse> => {
  return (
    axios
      .get(`${GET_NOTIFICATION_LOG_URL}`, {
        params: query,
      })
      .then((d: AxiosResponse<any>) => {
        const list = d.data.data.list;
        const pagination = d.data.data.meta;
        
        return {
          data: list,
          pagination: {
            page: pagination?.page,
            items_per_page: pagination?.limit,
            links: linkPagination(
              parseInt(pagination?.page),
              parseInt(pagination?.total),
              parseInt(pagination?.limit)
            ),
            total: pagination?.total,
          },
        };
      })
  );
};

const getNotificationLogsType = (query?: any): Promise<any> => {
  return (
    axios
      .get(`${GET_NOTIFICATION_LOG_TYPE_URL}`, {
        params: query,
      })
      .then((d: AxiosResponse<any>) => {
        return d.data
      })
  );
};

export { getNotificationLogs, getNotificationLogsType };
