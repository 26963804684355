import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { Sidebar } from "./components/sidebar";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from "../partials";
import { PageDataProvider } from "./core";
import { reInitMenu } from "../helpers";
import { ToolbarWrapper } from "./components/toolbar";
import { LayoutContent } from "./LayoutContent";
import { FilterHeaderDataProvider } from "./core/FilterHeaderData";

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  return (
    <PageDataProvider>
      <FilterHeaderDataProvider>
        <ThemeModeProvider>
          <LayoutContent />
        </ThemeModeProvider>
      </FilterHeaderDataProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
