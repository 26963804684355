import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { getPaginationUserLog } from "./core/_requests";
import { usersColumns } from "./table/_listPostsOfUserColumn";
import TableSummary from "app/_components/coreListData/header/TableSummary";

const ListUserLog = () => {
  const intl = useIntl();
  const users = useQueryResponseData();
  const res = useQueryResponsePagination();

  const data = useMemo(() => users, [users]);

  const cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL-LOG",
      }),
      value: res?.total,
    },
  ];

  return (
    <>
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.USER_LOG} />
      <div>
        <KTCard>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <TableSummary cardList={cardList} />
          </div>
          <Table
            defineColumns={usersColumns}
            data={data}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
          />
        </KTCard>
      </div>
    </>
  );
};

const ListUserLogWrapper = () => {
  const params = useParams();
  return (
    <TableWrapper params={params.id} getPanigation={getPaginationUserLog}>
      <ListUserLog />
    </TableWrapper>
  );
};

export default ListUserLogWrapper;
