import { useState } from "react";
import { useIntl } from "react-intl";

export default function TabsProfileInfo({ handleChangeTab, user }: any) {
  const intl = useIntl();

  const [tab, setTab] = useState("0");

  const hanldeChangeTab = (tab: string) => {
    setTab(tab);
    handleChangeTab(tab);
  };

  return (
    <div className='profile-page-tab d-flex overflow-auto px-6 mb-6 mt-0 pt-0'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item cursor-pointer' onClick={() => hanldeChangeTab("0")}>
          <div className={`nav-link text-active-primary me-6 ` + (tab === "0" && "active")}>
            {intl.formatMessage({ id: "POST" })}
          </div>
        </li>
        <li className='nav-item cursor-pointer' onClick={() => hanldeChangeTab("1")}>
          <div
            className={`nav-link text-active-primary py-0 my-0 me-6 ` + (tab === "1" && "active")}
          >
            {intl.formatMessage({ id: "PURCHASE" })}
          </div>
        </li>
        <li className='nav-item cursor-pointer' onClick={() => hanldeChangeTab("2")}>
          <div
            className={`nav-link text-active-primary py-0 my-0 me-6 ` + (tab === "2" && "active")}
          >
            {/* Connect social */}
            {intl.formatMessage({ id: "CONNECT" })}
          </div>
        </li>
        {user?.type == 3 && (
          <li className='nav-item cursor-pointer' onClick={() => hanldeChangeTab("3")}>
            <div
              className={`nav-link text-active-primary py-0 my-0 me-6 ` + (tab === "3" && "active")}
            >
              {intl.formatMessage({ id: "USER.BIZ.ACCOUNT" })}
            </div>
          </li>
        )}
        {/* <li className="nav-item" onClick={() => hanldeChangeTab("2")}>
          <div
            className={
              `nav-link text-active-primary py-0 my-0 me-6 ` +
              (tab === "2" && "active")
            }
          >
            {intl.formatMessage({ id: "LOGS" })}
          </div>
        </li> */}
      </ul>
    </div>
  );
}
