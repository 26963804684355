import { KTIcon } from "_metronic/helpers";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CreateAppModal } from "./ModalCreateEvent";

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const UsersListToolbar = ({
  show,
  handleClose,
  info,
  triggerListNotify,
  handleRefetch,
  triggerListEvent,
}: any) => {
  const intl = useIntl();

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  return (
    <div
      className="d-flex justify-content-end mb-4 p-8 pb-0"
      data-kt-user-table-toolbar="base"
    >
      {/* <UsersListFilter /> */}

      <div className="card-toolbar"></div>

      {/* begin::Add user */}
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setShowCreateAppModal(true)}
      >
        <KTIcon iconName="plus" className="fs-2" />
        {intl.formatMessage({ id: "ADD-EVENT" })}
      </button>
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        triggerListNotify={handleRefetch}
        triggerListEvent={triggerListEvent}
      />
      {/* end::Add user */}
    </div>
  );
};

export { UsersListToolbar };
